import { ApplicationState } from "framework/applicationState"
import { ModuleProps } from "framework/modules"
import { observer } from "mobx-react"
import { makeAutoObservable } from "mobx"
import React from "react"
import { UrlBuilder } from "tools/urlBuilder"

type IntegrationWrapperProps = ModuleProps & {
	name: string,
 	url: string
}

export function integrationFullUrl(url: string) {
	return new UrlBuilder(url)
		.add('cvSessionId', ApplicationState.apiToken)
		.add('cvAccountId', ApplicationState.accountId)
		.build()
}

export class IntegrationWrapperStore {
	name: string
	url: string
	constructor(props: IntegrationWrapperProps) {
		this.name = props.name
		this.url = props.url
		makeAutoObservable(this)
		props.initialized({
			title: decodeURI(this.name),
			helpKey: this.helpKey,
		})
	}

	get helpKey(): string {
		return 'integration_' + this.url.replace('/', '')
	}

	get fullUrl() {
		return integrationFullUrl(this.url)
	}
}

const IntegrationWrapperContext = React.createContext<IntegrationWrapperStore>(null)

const IntegrationWrapper = observer(() => {
	const store = React.useContext(IntegrationWrapperContext)
	return <embed id="integration_container" src={store.fullUrl} width="100%" height="100%">
	</embed>
})

export { IntegrationWrapper as default, IntegrationWrapperStore as Store, IntegrationWrapperContext as Context }
