import {DataSourceElement} from 'controls/designer/dataSourcesManager/dataSourceElement';
import {translator} from "core/localization/localization";
import {indicatorToState} from "tools/states";
import {formatNumber} from "tools/helpers/math";
import {CostValueType} from "areas/cost/models/costValueType";
import {CostBudgetEvent, CostReportEvent, EventType} from "framework/entities/events";
import {toStringTimeSelection} from "controls/react/form/timePeriodSelector";
import {compareByOperator} from "tools/helpers/math";
import {ShowCurrencyMode} from "controls/designer/dataSourcesManager/costShared";
import {CostsRouter} from "../../../areas/cost/bundleDescription";

const i = translator();


export class CostDataSourceElement extends DataSourceElement {
	constructor(designer, cell) {
		super(designer, cell);
	}

	getEntriesToLoad() {
		return [{
			costProfileId: this.datasource.costProfileId,
			costItemId: this.datasource.costItemId,
			valueType: this.datasource.valueType,
			timePeriod: toStringTimeSelection(this.datasource.timePeriod),
			currency: this.datasource.currency,
			costModelId: this.datasource.modelId
		}];
	}

	onHealthInfoLoaded(entries) {
		this.cost = entries.length ? entries[0] : null;
	}

	updateState() {
		if(this.cost == null)
			return;

		let value = this.getValue()

		if(!value)
			return;

		if(this.datasource.showCurrency == ShowCurrencyMode.Symbol) {
			value += ' ' + (this.cost.currencySymbol ?? '')
		}

		if(this.datasource.showCurrency == ShowCurrencyMode.Text) {
			value += ' ' + (this.cost.currencyText ?? '')
		}

		if(this.datasource.showCurrency == ShowCurrencyMode.Code) {
			value += ' ' + (this.cost.currencyCode ?? '')
		}

		if (!this.datasource.metricValueAsSeverity || !this.datasource.hideMetricValue) {
			this.addContentLabel(value);
		}

		if(this.datasource?.metricValueAsSeverity) {
			const valueToSeverity = this.datasource.metricValueToSeverity;

			let severityIndicator = valueToSeverity.findLastIndex(x => compareByOperator(x.operator ?? this.datasource.operator, this.cost.value || 0, x.threshold)) + 1;
			this.setState(indicatorToState(severityIndicator));
		}
	}

	getValue(){
		const decimalsNumber = this.datasource.decimalsNumber || 0;
		return formatNumber(this.cost.value, decimalsNumber);
	}

	getSubscriptionsDirectly() {
		if(this.datasource.valueType == CostValueType.Budget){
			return [CostBudgetEvent.subscriptionByModel(this.datasource.modelId, [this.datasource.costItemId] )];
		}else{
			return [CostReportEvent.subscription(this.datasource.modelId, toStringTimeSelection(this.datasource.timePeriod))];
		}
	}

	consumeEvent(event) {
		if (event.eventType != EventType.CostBudget && event.eventType != EventType.CostReport)
			return {
				redraw: false,
				reload: false
			};
		return {
			reload: true,
			redraw: false
		}
	}

	getTooltipInternal(accountName) {
		if (this.cost == null)
			return null;

		let result = this.cost.costProfileName + '/' + this.cost.costModelName + '/' + this.cost.costItemName;

		result += '\n'

		result += this.getValue() + " " + this.cost.currencySymbol

		return result
	}

	redirect() {
		if(!this.cost)
			return;
		const url = CostsRouter.details(this.cost.costProfileId, this.cost.costItemId, this.cost.costModelId)
		this.navigator.go({url})
	}

	empty(){
		return this.datasource?.costProfileId == null;
	}

	getLabel(){
		if (!this.cost) {
			return null;
		}
		if(this.datasource.showAsLabel){
			return this.cost.costProfileName + '/' + this.cost.costItemName;
		}

		return null;
	}
}
