import {translator} from "../../core";

const i = translator({})

export function getDescriptions(){
	return [{
		url: '/data-registry',
		getModuleImport: () => import('./data-registry/dataRegistryPage'),
		react: true,
		routeId: 'data_registry'
	}, {
		jspUrl: 'include/Management/AgentsView.jsp',
		url: '/agents',
		getModuleImport: () => import('./agentsView'),
		routeId: 'management_agents'
	}, {
		jspUrl: 'include/Management/AgentsView.jsp',
		url: '/agents/configuration',
		getModuleImport: () => import('./agentConfiguration'),
		routeId: 'management_agents_configuration'
	}, {
		jspUrl: 'include/Management/BundleConfiguration.jsp',
		url: '/agents/:agentId/bundle-configuration/:bundleId',
		getModuleImport: () => import('./bundleConfiguration'),
		routeId: 'bundle_configuration'
	}, {
		url: '/server-details',
		getModuleImport: () => import('./serverDetails'),
		routeId: 'server_details'
	}, {
		jspUrl: 'include/Management/UsersView.jsp',
		bodyCssClass: 'cw_management_module',
		url: '/active-users',
		getModuleImport: () => import('./usersView'),
		routeId: 'management_users'
	}, {
		jspUrl: 'include/Management/RepositoryView.jsp',
		url: '/repository',
		getModuleImport: () => import('./repositoryView'),
		routeId: 'management_repository'
	}, {
		jspUrl: 'include/Management/Modules.jsp',
		url: '/modules',
		getModuleImport: () => import('./modulesView'),
		bodyCssClass: 'cw_management_module',
		routeId: 'management_modules'
	}, {
		jspUrl: 'include/Management/Audit.jsp',
		url: '/audit',
		getModuleImport: () => import('./managementAudit'),
		bodyCssClass: 'cw_management_module',
		routeId: 'management_audit'
	}, {
		jspUrl: 'include/Management/SystemErrors.jsp',
		url: '/system-messages',
		getModuleImport: () => import('./systemErrorsView'),
		bodyCssClass: 'cw_management_module management_system_errors',
		routeId: 'management_system_errors'
	}];
}

export class ManagementRouter {
	static agentsList() {
		return '/agents'
	}

	static dataRegistryList() {
		return '/data-registry'
	}

	static repository() {
		return '/repository'
	}

	static modules() {
		return '/modules'
	}

	static audit() {
		return '/audit'
	}

	static systemMessages() {
		return '/system-messages'
	}

	static bundleConfiguration(bundleId, agentId, configurationId, bundleName) {
		return `/agents/${agentId}/bundle-configuration/${bundleId}?configurationId=${configurationId}&bundleName=${bundleName}`
	}

	static serverDetails() {
		return '/server-details'
	}

	static activeUsers() {
		return '/active-users'
	}
}

export const defaultBreadcrumbItems = () => {
	return [{
		title: i('Management'),
		url: '#' + ManagementRouter.agentsList()
	}]
}
