import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";

import {MenuButton} from "controls/react";
import {GridNative} from "controls/react/kendoWrappers/gridNative";
import {Section} from 'controls/react/layout/section';
import {Toolbar} from 'controls/react/layout/toolbar';
import {translator} from "core";
import {fromJS} from "immutable";
import {multigraphMetricSelectorGridColumns} from "../common/dataSources/multigraphMetricSelectorGridColumns";
import {multigraphMetricSelectorGridSchema} from "../common/dataSources/multigraphMetricSelectorGridSchema";
import {DataRegistryWindow} from "areas/management/data-registry/dataRegistryWindow";
import {ConversionWindow} from "../common/conversionWindow";
import {AdvancedMetricFieldsWindow} from "../common/advancedMetricFieldsWindow";
import {useStore} from "./useStore";
import {newGuid} from "tools/guid";
import Button from "controls/react/form/button";
import {searchMetrics} from "areas/metrics/api";
import {apiFetch} from "framework/api";
import {isEqual} from 'lodash';
import {ApplicationState} from "framework/applicationState";
import GridMenu from "controls/gridMenu";

const b = require('b_').with('metric-selector');

const i = translator({
	"Select metrics": {
		"no": "Velg metrikker",
		"en": "Select metrics"
	},
	"Show advanced": {
		"no": "Vis avansert",
		"en": "Show advanced"
	},
	"Hide advanced": {
		"no": "Skjul avansert",
		"en": "Hide advanced"
	},
	"Advanced tooltip": {
		"en": "Click to update advanced settings",
		"no": "Klikk for å endre avanserte innstillinger"
	}
});

MetricSelector.propTypes = {};

const advancedFields = ['color', 'decimals', 'unit', 'unitLabel', 'conversion', 'customUnit']
/**
 * @return {null}
 */
export function MetricSelector(props) {
	let showAdvanced = false;
	const menuRef = useRef();
	const [type] = useStore(["type"]);
	const [accountId] = useStore(["configuration", "accountId"]);
	const [selectedIds, setIds] = useStore(["configuration", "metrics"]);
	const [selectedMetrics, setSelectedMetrics] = useStore(["configuration", "metricsItems"]);
	const [openWindow, setOpenWindow] = useState(false);
	const [openConversionWindow, setOpenConversionWindow] = useState(false);
	const [openAdvancedWindow, setOpenAdvancedWindow] = useState(false);
	const [conversionMetricId, setConversionMetricId] = useState();
	const [conversionMetric, setConversionMetric] = useState();
	const [conversionWindowOffset, setConversionWindowOffset] = useState();
	const [conversionFormula, setConversionFormula] = useState();
	const [conversionDecimals, setConversionDecimals] = useState();
	const [allColumns, setAllColumns] = useState(showAdvanced);
	const [toggleClicked, setToggleClicked] = useState(false);
	const checkedRowsRef = useRef([]);
	const handleDeleteRef = useRef(null);

	const [fullMetrics, setFullMetrics] = useState([]);
	const [currentAccountId, setCurrentAccountId] = useState(accountId);

	useEffect(() => {
		setCurrentAccountId(props.useDefaultAccount ? ApplicationState.accountId : accountId ?? ApplicationState.accountId);
	}, [accountId, props.useDefaultAccount]);

	const getFullMetric = (id) => {
		return fullMetrics.find(x => x.metricId === id)
	}

	if (!toggleClicked) {
		const showAdvanced = fullMetrics.some(x => {
			return advancedFields.some(field => x[field])
		})

		if (showAdvanced !== allColumns) {
			setAllColumns(showAdvanced);
		}
	}

	const refreshGridToken = useMemo(() => newGuid(), [allColumns]);

	useEffect(() => {
		const updateGridMetrics = async () => {
			const selectedMetricsArray = selectedMetrics.toJS()
			const ids = selectedMetricsArray.map(x => x.metricId)
			const gridIds = fullMetrics.map(x => x.metricId)
			let tempMetrics = fullMetrics
			if(!isEqual(ids, gridIds)) {
				const result = await fetchSelectedItems(ids)
				tempMetrics = result
			}
			// we need to fill advanced field, because we show it in grid
			selectedMetricsArray.forEach(m => {
				let loadedMetric = tempMetrics.find(r => m.metricId === r.metricId)
				if(loadedMetric){
					loadedMetric.advanced = m.advanced
				}
			})
			setFullMetrics([...tempMetrics])
			setIds(ids)
		}
		selectedMetrics && updateGridMetrics()
	}, [selectedMetrics]);

	handleDeleteRef.current = useCallback(() => {
		const metrics = selectedMetrics.toJS();

		for (const checkedId of checkedRowsRef.current) {
			const index = metrics.findIndex((metric) => metric.metricId === checkedId);
			metrics.splice(index, 1);
		}

		setSelectedMetrics(fromJS(metrics));
	}, [selectedMetrics]);

	const menuItems = useMemo(() => {
		return [{
			icon: 'plus',
			text: i('Add'),
			fn: () => {
				setOpenWindow(true);
			}
		}, {
			icon: 'bin',
			text: i('Delete'),
			fn: () => {
				handleDeleteRef.current();
			},
			enabledIf: GridMenu.AtLeastOneItemSelected,
			disabled: true
		}];
	}, []);

	const openMetricWindow = useCallback(() => {
		if (currentAccountId) {
			setOpenWindow(true);
		}
	}, [currentAccountId]);

	const onClose = useCallback(() => {
		setOpenWindow(false);
	});

	const onConversionClose = useCallback(() => {
		setOpenConversionWindow(false);
	});

	const onAdvancedClose = useCallback(() => {
		setOpenAdvancedWindow(false);
	});

	const onConversionUpdate = useCallback((formula, decimals) => {
		let row = $('[metric-id=' + conversionMetricId + ']').closest('tr');
		let grid = row.closest('.k-grid').data('kendoGrid');
		let dataItem = grid.dataItem(row);
		dataItem.set('conversion', formula);
		dataItem.set('decimals', decimals);
		let metrics = selectedMetrics.toJS();
		for (let i = 0; i < metrics.length; i++) {
			if (metrics[i].metricId === conversionMetricId) {
				metrics[i].conversion = formula;
				metrics[i].decimals = decimals;
			}
		}
		setSelectedMetrics(fromJS(metrics));
		setOpenConversionWindow(false);
	});

	const onAdvancedUpdate = useCallback((fields) => {
		let metrics = selectedMetrics.toJS();
		const metric = metrics.find(x => x.metricId === conversionMetricId);

		metric.advanced = fields.advanced;
		metric.unitLabel = fields.advanced ? fields.unitLabel : null;
		metric.unit = fields.advanced ? fields.unit : null;
		metric.formula = fields.advanced ? fields.formula : null;
		metric.decimals = fields.advanced ? fields.decimals : null;
		metric.color = fields.advanced ? fields.color : null;

		setSelectedMetrics(fromJS(metrics));
		setOpenAdvancedWindow(false);
	});

	const onAdd = useCallback( async (itemsIds) => {
		setOpenWindow(false)
		if (!(itemsIds.length || type !== 'metric-single-graph')) {
			setSelectedMetrics(fromJS([]));
			return
		}

		let currentMetrics = selectedMetrics?.toJS() || []
		let currentMetricIds = currentMetrics.map(metric => metric.metricId)
		//remove old items
		currentMetrics = currentMetrics.filter(x => itemsIds.includes(x.metricId))
		//add new items
		const addedIds = itemsIds.filter(x => !currentMetricIds.includes(x))
		currentMetrics = currentMetrics.concat(addedIds.map(x => ({metricId: x})))

		setSelectedMetrics(fromJS(currentMetrics))
	});

	async function fetchSelectedItems(items) {
		if(items.length === 0)
			return [];

		const result = await apiFetch(searchMetrics({ids: items, currentAccountId, includeSubaccounts: true}))
		if(result.success) {
			return result.data.items.map(x => x.data)
		}
		return []
	}

	const selectedMetricsRef = useRef();
	useEffect(() => {
		selectedMetricsRef.current = selectedMetrics;
	}, [selectedMetrics]);

	const onGridChanged = useCallback((e) => {
		const {values, model} = e;
		const updatedItem = model.toJSON();
		const newSelectedMetrics = selectedMetricsRef.current.toJS();

		const index = newSelectedMetrics.findIndex((i) => i.metricId === updatedItem.metricId);

		if (index === -1) {
			return;
		}

		newSelectedMetrics.splice(index, 1, {...updatedItem, ...values});

		setSelectedMetrics(fromJS(newSelectedMetrics));
	}, [selectedMetrics]);

	const onDataBound = useCallback((e) => {
		$('.cw_conversion').closest('td').addClass('pointer').on('click', (e) => {
			onConversion(e);
		})
		$('.cw_advanced_column').closest('td').addClass('pointer').attr('title', i('Advanced tooltip')).on('click', (e) => {
			onAdvanced(e);
		});
		$('.cw_metric_item_delete').on('click', (e) => {
			onMetricItemDelete(e);
		});
		let grid = $(e.sender.wrapper).data('kendoGrid');
		let gridDataSource = grid.dataSource.data().toJSON();
		if (!gridDataSource.length) {
			if (!grid.wrapper.find('.k-grid-norecords').length) {
				grid.wrapper.find('.k-grid-content-expander').before('<div class="k-grid-norecords cw_no_records_container pointer"><span class="cw_no_records_info">' + lang.widget.messages.SELECT_NEW_METRICS + '<span></span></div>');
				grid.wrapper.find('.k-grid-content').css('overflow-y', 'hidden');
				grid.wrapper.find('.k-grid-norecords').on('click', setOpenWindow);
			}
		} else {
			grid.wrapper.find('.k-grid-norecords').remove();
			grid.wrapper.find('.k-grid-content').css('overflow-y', 'scroll');
		}
	});

	const onAdvanced = (e) => {
		let selectedMetrics = selectedMetricsRef.current.toJS();
		let target = $(e.target);
		let formula = target.text();
		let decimals = target.attr('decimals');
		let targetOffset = target.offset();
		let metricId = target.closest('tr').find('.cw_grid_check').attr('metric-id');
		if (selectedMetrics) {
			for (let metric of selectedMetrics) {
				if (metric.metricId === metricId) {
					setConversionMetric(metric);
				}
			}
		}
		setConversionMetricId(metricId);
		setConversionWindowOffset(targetOffset);
		setConversionFormula(formula);
		setConversionDecimals(decimals);
		setTimeout(() => {setOpenAdvancedWindow(true)}, 100);
	}

	const onConversion = (e) => {
		let selectedMetrics = selectedMetricsRef.current.toJS();
		let target = $(e.target);
		let formula = target.text();
		let decimals = target.attr('decimals');
		let targetOffset = target.offset();
		let metricId = target.closest('tr').find('.cw_grid_check').attr('metric-id');
		if (selectedMetrics) {
			for (let metric of selectedMetrics) {
				if (metric.metricId === metricId) {
					setConversionMetric(metric);
				}
			}
		}
		setConversionMetricId(metricId);
		setConversionWindowOffset(targetOffset);
		setConversionFormula(formula);
		setConversionDecimals(decimals);
		setTimeout(() => {setOpenConversionWindow(true)}, 100);
	}

	const onMetricItemDelete = (e) => {
		let selectedMetrics = selectedMetricsRef.current.toJS();
		let metricId = $(e.target).closest('tr').find('.cw_grid_check').attr('metric-id');
		for (let i = 0; i < selectedMetrics.length; i++) {
			if (selectedMetrics[i].metricId === metricId) {
				selectedMetrics.splice(i, 1);
			}
		}
		setSelectedMetrics(fromJS(selectedMetrics));
	}

	const onAdvancedToggle = (e) => {
		setToggleClicked(true);
		if ($(e.target).hasClass('selected_advanced_metrics_toggle')) {
			setAllColumns(false);
		} else {
			setAllColumns(true);
		}
	}

	const handleRowSelected = useCallback((ids) => {
		checkedRowsRef.current = ids;
		menuRef.current.gridMenu.setRowsSelectedCount(ids.length);
	});

	useEffect(() => {
		menuRef.current?.gridMenu.setRowsSelectedCount(checkedRowsRef.current?.length ?? 0);
	}, [menuRef.current])

	if (type === 'multi_graph_assetgroup' || type === 'metric-multi-graph-asset-group') {
		return null;
	}

	let advancedToggleContainerClass = allColumns ? 'advanced_metrics_toggle selected_advanced_metrics_toggle' : 'advanced_metrics_toggle';
	let advancedToggleMouseover = allColumns? i('Hide advanced') : i('Show advanced');

	let gridHeight = 200;
	$('#cw_metrics_form .k-grid-content').css('height', gridHeight);

	return (
		<Section containerClass={b()} contentPadding={false}>
			<Toolbar title={lang.widget.SELECTED_METRICS}>
				<MenuButton items={menuItems} ref={menuRef} />
				<Button title={i('Select metrics')}
				        primary
				        onClick={openMetricWindow}/>
			</Toolbar>
			<GridNative
				key={refreshGridToken}
				selectionField={"metricId"}
				onRowsSelected={handleRowSelected}
				columns={multigraphMetricSelectorGridColumns()}
				schema={multigraphMetricSelectorGridSchema()}
				dataSourceArray={fullMetrics}
				resizable={true}
				editable={true}
				height={gridHeight}
				oneRowSelection={type === 'metric-single-graph' || type === 'single_graph'}
				dataBound={onDataBound}
				onSave={onGridChanged} />
			{openWindow && currentAccountId && <DataRegistryWindow
				onClose={onClose}
				onAdd={onAdd}
				accountId={currentAccountId}
				singleMetricSelection={type === 'metric-single-graph' || type === 'single_graph'}
				defaultSelectedMetricIds={selectedIds} />}
			{openAdvancedWindow && <AdvancedMetricFieldsWindow
				offset={conversionWindowOffset}
				type={type}
				accountId={currentAccountId}
				metric={conversionMetric}
				metricUnitType={getFullMetric(conversionMetricId).unitType}
				onClose={onAdvancedClose}
				onUpdate={onAdvancedUpdate}
			/>}
			{openConversionWindow && <ConversionWindow
				metricId={conversionMetricId}
				onUpdate={onConversionUpdate}
				onClose={onConversionClose}
				offset={conversionWindowOffset}
				formula={conversionFormula}
				decimals={conversionDecimals}
			/>}
		</Section>
	);
}
