import './timePeriodSelector.less'

import React, {useEffect} from 'react'
import moment from 'moment'
import {primitive} from 'serializr'
import {observer} from "mobx-react"
import {makeAutoObservable} from 'mobx'
import {CalendarOutlined, FieldTimeOutlined, OrderedListOutlined, RetweetOutlined, NumberOutlined} from '@ant-design/icons'
import {createRoot, Root} from "react-dom/client";
import {debounce, memoize, sortBy} from "lodash";


import {AntSelect, AntSelectOption, AntTag} from "controls/react/ant/antSelect"
import {AntRangePicker} from "controls/react/ant/antDatePicker"
import {AntPopover} from "controls/react/ant/antPopover"
import {createModelSchemaWrapper} from "framework/serializr-integration"
import {AntRadioGroup} from "controls/react/ant/antRadio"
import {AntSizeType} from "controls/react/ant/utils"
import {AntInputNumber} from "controls/react/ant/antInputNumber"
import {MobxManager} from "framework/mobx-integration"
import {UrlBuilder} from "tools/urlBuilder"
import {isOneOf} from "tools/utils"
import {TimePeriodType} from "controls/react/form/timePeriodType";
import {AntButton} from "controls/react/ant/antButton";

const i18n = require('core/localization/localization').translator({
  "LAST30DAYS": {
    "en": "Last 30 days",
    "no": "Siste 30 dager"
  },
  "LAST30DAYS_short": {
    "en": "30d",
    "no": "30d"
  },
  "LAST7DAYS": {
    "en": "Last 7 days",
    "no": "Siste 7 dager"
  },
  "LAST7DAYS_short": {
    "en": "7d",
    "no": "7d"
  },
  "LASTDAY": {
    "en": "Yesterday",
    "no": "Siste dag"
  },
  "LASTDAY_short": {
    "en": "24h",
    "no": "24h"
  },
  "LASTHOUR": {
    "en": "Hour",
    "no": "Time"
  },
  "LASTHOUR_short": {
    "en": "1h",
    "no": "1h"
  },
  "CURRENT_MONTH": {
    "en": "Current month",
    "no": "Nåværende måned"
  },
  "LAST_MONTH": {
    "en": "Last month",
    "no": "Nåværende måned"
  },
  "CURRENT_YEAR": {
    "en": "Current year",
    "no": "Nåværende år"
  },
  "CUSTOM": {
    "en": "Static Range",
    "no": "Tilpasset"
  },
  "DYNAMIC": {
    "en": "Dynamic interval ({0}{1})",
    "no": "Dynamisk intervall ({0}{1})"
  },
  "DYNAMIC_short": {
    "en": "{0}{1}",
    "no": "{0}{1}"
  },
  "CUSTOM_short": {
    "en": "Custom",
    "no": "Tilpasset"
  },
  "CURRENT_WEEK": {
    "en": "Current week",
    "no": "Nåværende uke"
  },
	'CURRENT_DAY': {
	  'en': 'Today'
	},
  "LAST_YEAR": {
    "en": "Last year",
    "no": "Forrige år"
  },

	'PREVIOUS_QUARTER': {
	  en: 'Quarter'
	},

	'PREVIOUS_WEEK': {
	  en: 'Week'
	},
	'PREVIOUS_DAY': {
	  en: 'Yesterday'
	},
  "None": {
    "no": "Ingen"
  },
  "YEAR": {
    "en": "Year",
    "no": "År"
  },
  "MONTH": {
    "en": "Month",
    "no": "Måned"
  },
  "ALL": {
    "en": "All",
    "no": "Alle"
  },
  "ALL_short": {
    "en": "All",
    "no": "Alle"
  },
	"LASTN": {
		"en": "Last {0}",
		"no": "Siste {0}"
	},
	"LASTN_short": {
		"en": "{0}",
		"no": "{0}"
	},
  "Click to set static time range": {
    "no": "Klikk for å sette statisk tid"
  },
  "Click to change dynamic time range. Max value is {0} Days.": {
    "no": "Klikk for å endre dynamisk tid. Max value is {0} Days."
  },
	'Click to switch to Last n data selection' : {
	  no: 'Klikk for å bytte til Last n datavalg'
	},
	'Click to switch to time data selection' : {
		no: 'Klikk for å bytte til tid datavalgg'
	},
	'Last N: Specify the number of most recent data points to display.': {
	  no: 'Siste N: Spesifiser antall siste datapunkter som skal vises.'
	}
});

export enum IntervalType{
	Seconds = 'SECONDS',
	Minutes = 'MINUTES',
	Hours = 'HOURS',
	Days = 'DAYS'
}

let intervalTypeDataSource: AntSelectOption[]

function getIntervalTypeDataSource(){
	if(intervalTypeDataSource == null){
		intervalTypeDataSource = [
			{label: i18n('Seconds'), value: IntervalType.Seconds},
			{label: i18n('Minutes'), value: IntervalType.Minutes},
			{label: i18n('Hours'), value: IntervalType.Hours},
			{label: i18n('Days'), value: IntervalType.Days},
		]
	}

	return intervalTypeDataSource
}

export class TimePeriod {
	period: TimePeriodType
	startDate?: number
	endDate?: number
	year?: number
	months?: number
	intervalValue?: number
	intervalType?: IntervalType
	lastNValue?: number

	constructor() {
		makeAutoObservable(this)
	}
}

createModelSchemaWrapper(TimePeriod, {
	period: primitive(),
	startDate: primitive(),
	endDate: primitive(),
	year: primitive(),
	months: primitive(),
	intervalType: primitive(),
	intervalValue: primitive(),
	lastNValue: primitive()
})

export type TimeSelectionString = {
	period: TimePeriodType;
	startDate?: string;
	endDate?: string;
	year?: number;
	months?: number;
	month?: number;
	intervalValue?: number;
	lastNValue?: number;
	intervalType?: IntervalType;
}
export enum TimePeriodAppearance{
	DropDown,
	Buttons,
	Hoverable,
	Auto // Buttons || Hoverable
}

export enum TimePeriodLabelSize{
	Short,
	Full
}

export class TimePeriodSelectorProps{
	periods: TimePeriodType[]
	value?: TimePeriod
	onChange?: (selection: TimePeriod) => void
	appearance?: TimePeriodAppearance
	size?: AntSizeType
	onModal?: boolean
	labelsSize?: TimePeriodLabelSize
}

export function toStringTimeSelection(period: TimePeriod) : TimeSelectionString{
	return {
		period: period.period,
		startDate: period.startDate != null ? moment(period.startDate).format("YYYY-MM-DD") : null,
		endDate: period.endDate != null ? moment(period.endDate).format("YYYY-MM-DD") : null,
		year: period.year,
		months: period.months,
		intervalValue: period.intervalValue,
		lastNValue: period.lastNValue,
		intervalType: period.intervalType
	}
}

export function timePeriodUrlParams(period: TimePeriod): {[id: string]: string | number | undefined} {
	return {
		timeSelector: period.period,
		fromTime: period.period == TimePeriodType.Custom ? new Date(period.startDate).getTime() : undefined,
		toTime: period.period == TimePeriodType.Custom ? new Date(period.endDate).getTime() : undefined,
		intervalType: period.period == TimePeriodType.DynamicInterval ? period.intervalType : undefined,
		intervalValue: period.period == TimePeriodType.DynamicInterval ? period.intervalValue : undefined,
		lastNValue: period.period == TimePeriodType.LastN ? period.lastNValue : undefined
	};
}

export function timePeriodToUrl(period: TimePeriod) {
	const params = timePeriodUrlParams(period);
	return Object.keys(params)
		.filter(key => !!params[key])
		.map(key => `${key}=${params[key]}`)
		.join('&');
}

export function fillPeriodForBuilder(builder: UrlBuilder, period: TimePeriod){
	return builder.add('timeSelector', period.period)
		.add('fromTime', period.startDate, period.period == TimePeriodType.Custom)
		.add('toTime', period.endDate, period.period == TimePeriodType.Custom)
		.add('intervalType', period.intervalType, period.period == TimePeriodType.DynamicInterval)
		.add('intervalValue', period.intervalValue, period.period == TimePeriodType.DynamicInterval)
		.add('lastNValue', period.lastNValue, period.period == TimePeriodType.LastN)
}

export function timePeriodToDates(period: TimePeriod, baseDate?: Date) {
	if (period.period == TimePeriodType.Custom) {
		return {startDate: moment(period.startDate), endDate: moment(period.endDate)}
	}

	let m = moment(baseDate ?? new Date())

	let startDate: moment.Moment
	let endDate: moment.Moment

	switch (period.period) {
		case TimePeriodType.PreviousQuarter:
			startDate = m.add(-m.month()%3 - 3, 'months' ).startOf('month')
			endDate = startDate.clone().add(3, 'months')
			break

		case TimePeriodType.PreviousMonth:
			endDate = m.startOf('months')
			startDate = endDate.clone().startOf('months').add(-1, 'months')
			break

		case TimePeriodType.PreviousDay:
			endDate = m.startOf('day')
			startDate = endDate.clone().add(-1, 'day')
			break

		case TimePeriodType.PreviousWeek:
			endDate = m.startOf('week')
			startDate = endDate.clone().add(-1, 'week')
			break

		case TimePeriodType.Last7Days:
			endDate = m.startOf('day')
			startDate = endDate.clone().add(-1, 'week')
			break

		case TimePeriodType.CurrentDay:
			startDate = m.startOf('day')
			endDate = startDate.clone().add(1, 'day')
			break

		default:
			console.error('no implementation for period: ' + period.period)
	}

	return {
		startDate: startDate,
		endDate: m.add(-1, 'millisecond')
	}
}

export function periodToMilliseconds(period: TimePeriod) {
	switch (period.period) {
		case TimePeriodType.LastHour:
			return 1000*60*60;
		case TimePeriodType.Last24Hours:
			return 1000*60*60*24;
		case TimePeriodType.Last7Days:
			return 1000*60*60*24*7;
		case TimePeriodType.Last30Days:
		case TimePeriodType.PreviousMonth:
			return 1000*60*60*24*30;
		case TimePeriodType.DynamicInterval:
		{
			switch (period.intervalType) {
				case IntervalType.Seconds:
					return 1000*period.intervalValue;
				case IntervalType.Minutes:
					return 1000*60*period.intervalValue;
				case IntervalType.Hours:
					return 1000*60*60*period.intervalValue;
				case IntervalType.Days:
					return 1000*60*60*24*period.intervalValue;
			}
			return period.intervalValue;
		}
	}
	return null;
}

const defaultIntervalValue = 12
const defaultIntervalType = IntervalType.Hours
const defaultLastCountValue = 100

const b = require('b_').with('time-period-selector')

const lastNOptions = [
	{label: '10', value: 10},
	{label: '100', value: 100},
	{label: '1000', value: 1000},
	{label: '10000', value: 10000},
]

enum SelectorMode {
	Time = 1,
	Count = 2
}

export class TimePeriodSelectorStore {
	value: TimePeriod

	showDateRangeSelector: boolean = false
	showIntervalSelector: boolean = false
	showHoverableTimeSelector: boolean = false

	props: TimePeriodSelectorProps

	tempDateRange: moment.Moment[] = [null, null]

	mobx = new MobxManager()

	//the interval selector is debounced
	intervalTempValue: number
	intervalTempType: IntervalType
	dateRangePickerOpened: boolean
	tempLastN: number
	lastNValueOptions: {label: string, value: number}[] = [...lastNOptions];
	searchedLastN: number;
	selectorMode: SelectorMode = SelectorMode.Time;

	onTempDateRangeChange = (dates: moment.Moment[], dateStrings: [string, string]) => {
		this.tempDateRange = dates
		this.showDateRangeSelector = false
	}

	constructor(props: TimePeriodSelectorProps) {
		this.props = { ...props }

		makeAutoObservable(this)

		this.mobx.reaction(() => this.showDateRangeSelector, () => {
			if (this.showDateRangeSelector) {
				this.tempDateRange[0] = this.props.value.startDate != null ? moment(this.props.value.startDate) : null
				this.tempDateRange[1] = this.props.value.endDate != null ? moment(this.props.value.endDate) : null
			} else {
				this.notifyDateRangeChanged()

				this.tempDateRange = [null, null]
			}
		})

		this.mobx.reaction(() => ({a: this.tempDateRange[0], b: this.tempDateRange[1]}), (value) => {
			if (this.props.appearance == TimePeriodAppearance.DropDown) {
				this.notifyDateRangeChanged()
			}
		})

		this.mobx.reaction(() => this.props.value, () => {
			if(this.props.value.period == TimePeriodType.DynamicInterval){
				this.intervalTempType = this.props.value.intervalType
				this.intervalTempValue = this.props.value.intervalValue
				this.selectorMode = SelectorMode.Time;
			}
			if(this.props.value.period == TimePeriodType.LastN) {
				this.intervalTempType = undefined;
				this.tempLastN = this.props.value.lastNValue;
				this.selectorMode = SelectorMode.Count;
			}
		}, {
			fireImmediately: true
		})

		this.mobx.reaction(() => this.intervalTempType, () => {
			if (this.intervalTempValue > this.maxIntervalValue) {
				this.intervalTempValue = Math.min(this.intervalTempValue, this.maxIntervalValue)
				this.triggerIntervalChangedBounced()
			}
		}, {
			fireImmediately: true
		})

		this.mobx.reaction(() => this.searchedLastN, () => {
			if (this.selectorMode != SelectorMode.Count) {
				return
			}
			let o = [...lastNOptions];
			const current = this.searchedLastN ?? defaultLastCountValue;
			if(lastNOptions.every(x => x.value != current)) {
				o.push({label: current.toString(), value: current})
			}
			this.lastNValueOptions = sortBy(o, ['value']);
		}, {
			fireImmediately: true
		})

		this.mobx.reaction(() => this.selectorMode, mode => {
			if (mode == SelectorMode.Time) {
				this.periodChanged(TimePeriodType.Last7Days)
			} else {
				this.periodChanged(TimePeriodType.LastN);
			}
		})
	}

	get startDate(){
		return this.props.value.startDate != null ? moment(this.props.value.startDate) : null
	}

	get endDate(){
		return this.props.value.endDate != null ? moment(this.props.value.endDate) : null
	}

	get customDateRangeEnabled(){
		return this.props.periods.indexOf(TimePeriodType.Custom) != -1
	}

	get lastNEnabled(){
		return this.props.periods.indexOf(TimePeriodType.LastN) != -1
	}

	get appearance(){
		return this.props.appearance ?? TimePeriodAppearance.DropDown
	}

	get anyPopoverOpened() {
		return this.showIntervalSelector || this.showDateRangeSelector
	}

	get periodOptions() {
		return this.props.periods
			//in case of buttons we show Custom date range button as a separate button
			//in case of dropdown it goes in the same list as every other button
			.filter(x => this.appearance == TimePeriodAppearance.DropDown || ![TimePeriodType.Custom, TimePeriodType.LastN].includes(x))
			.map(x => {
				if (x != TimePeriodType.DynamicInterval) {
					return {
						label: i18n(this.getPeriodLabelName(x)),
						value: x
					}
				} else {
					return {
						label: <span title={i18n('Click to change dynamic time range. Max value is {0} Days.', this.intervalLimitInDays)}>
							{i18n(this.getPeriodLabelName(x), this.props.value.intervalValue ?? defaultIntervalValue, this.getIntervalTypeShortcut())}
							<FieldTimeOutlined className={b('custom-interval-item')} />
						</span>,
						value: x
					}
				}
			})
	}

	get maxIntervalValue(){
		switch(this.intervalTempType){
			case IntervalType.Seconds:
				return this.intervalLimitInDays * 24 * 60 * 60
			case IntervalType.Minutes:
				return this.intervalLimitInDays * 24 * 60
			case IntervalType.Hours:
				return this.intervalLimitInDays * 24;
			case IntervalType.Days:
				return this.intervalLimitInDays
		}

		return null
	}

	get intervalLimitInDays(){
		return this.props.periods.some(x => x == TimePeriodType.All)
			? 999
			: 60
	}

	get customDateRangeOptions(){
		return [{
			label: <CalendarOutlined />,
			value: TimePeriodType.Custom
		}]
	}

	toggleMode = () => {
		this.selectorMode = this.selectorMode == SelectorMode.Time ? SelectorMode.Count : SelectorMode.Time;
	}

	getIntervalTypeShortcut(){
		switch(this.props.value.intervalType ?? defaultIntervalType){
			case IntervalType.Seconds:
				return 's'
			case IntervalType.Hours:
				return 'h'
			case IntervalType.Days:
				return 'd'

			default:
			case IntervalType.Minutes:
				return 'm'
		}
	}

	triggerIntervalValueChanged = (value: number) => {
		this.intervalTempValue = value
		this.triggerIntervalChangedBounced()
	}

	triggerIntervalTypeChanged = (type: IntervalType) => {
		this.intervalTempType = type
		this.triggerIntervalChangedBounced()
	}

	triggerIntervalChanged = () => {
		this.triggerOnChanged({
			period: TimePeriodType.DynamicInterval,
			intervalType: this.intervalTempType,
			intervalValue: this.intervalTempValue
		})
	}

	triggerIntervalChangedBounced = debounce(this.triggerIntervalChanged, 2000)

	triggerLastNChanged = () => {
		this.triggerOnChanged({
			period: TimePeriodType.LastN,
			lastNValue: this.tempLastN
		})
	}

	onTempLastNChange = (value: number) => {
		this.tempLastN = value
		this.triggerLastNChanged();
	}

	notifyDateRangeChanged = () => {
		if(this.tempDateRange.length != 2 || !this.tempDateRange[0] || !this.tempDateRange[1])
			return

		let initialStartDate = new Date(this.tempDateRange[0].valueOf())
		let initialEndDate = new Date(this.tempDateRange[1].valueOf())
		initialStartDate.setHours(0, 0, 0, 0)
		initialEndDate.setHours(24, 0, 0, 0)

		const currentValue = this.props.value

		if(currentValue.period == TimePeriodType.Custom
			&& currentValue.startDate == initialStartDate.getTime()
			&& currentValue.endDate == (initialEndDate.getTime() - 1))
		{
			return
		}

		this.triggerOnChanged({
			period: TimePeriodType.Custom,
			startDate: initialStartDate.getTime(),
			endDate: initialEndDate.getTime() - 1
		})
	}

	periodChanged = (period: TimePeriodType) => {
		if (period == TimePeriodType.DynamicInterval && this.isButtons) {
			this.showIntervalSelector = true
			this.triggerOnChanged({period})
		} else if (period == TimePeriodType.Custom && this.isButtons) {
			this.showDateRangeSelector = true
		} else if (period == TimePeriodType.LastN && this.isButtons) {
			this.triggerOnChanged({period})
		} else {
			this.showIntervalSelector = false
			this.showDateRangeSelector = false
			this.tempDateRange = [null, null]
			this.triggerOnChanged({period})
		}
	}

	triggerOnChanged(value: Partial<TimePeriod>) {
		const newValue: TimePeriod = {
			period: value.period ?? this.props.value.period
		}

		if (newValue.period == TimePeriodType.DynamicInterval) {
			newValue.intervalType = value.intervalType || this.props.value.intervalType || defaultIntervalType
		}

		if (newValue.period == TimePeriodType.DynamicInterval) {
			newValue.intervalValue = value.intervalValue || this.props.value.intervalValue || defaultIntervalValue
		}

		if (newValue.period == TimePeriodType.LastN) {
			newValue.lastNValue = value.lastNValue || this.props.value.lastNValue || defaultLastCountValue
		}

		if (newValue.period == TimePeriodType.Custom) {
			newValue.startDate = value.startDate ?? this.props.value.startDate
			newValue.endDate = value.endDate ?? this.props.value.endDate
		} else {
			newValue.startDate = null
			newValue.endDate = null
		}

		this.props.onChange(newValue)
	}

	checkIfNeedToShowCustomSelectors = (e: React.MouseEvent<HTMLDivElement>) => {
		// we need to show a time selector when a user clicks on a 'custom' when 'custom' (the same goes for Dynimac) i
		// s already selected (AntRadio do not trigger event in this case).

		// we're checking that currently selected is CUSTOM/DYNAMIC and
		if (![TimePeriodType.Custom, TimePeriodType.DynamicInterval, TimePeriodType.LastN].includes(this.props.value.period))
			return

		//that we clicked on a radio button
		const target = e.target as HTMLElement
		const radioEntry = target.closest('.ant-radio-button-wrapper')
		if (radioEntry == null)
			return

		if (radioEntry.querySelector('input').value == TimePeriodType.Custom){
			this.showDateRangeSelector = true
		}

		if (radioEntry.querySelector('input').value == TimePeriodType.DynamicInterval){
			this.showIntervalSelector = true
		}
	}

	getPeriodLabelName(period: TimePeriodType) {
		if (this.isButtons && this.props.labelsSize == null || this.props.labelsSize == TimePeriodLabelSize.Short) {
			return period + '_short'
		} else {
			return period
		}
	}

	onLastNSearch = (val: string) => {
		this.searchedLastN = !!val ? parseInt(val, 10) : 0;
		this.onTempLastNChangeDebounced(this.searchedLastN)
	}

	onTempLastNChangeDebounced = debounce(this.onTempLastNChange, 1000)

	get isButtons() {
		return isOneOf(this.appearance, [
			TimePeriodAppearance.Buttons,
			TimePeriodAppearance.Hoverable,
			TimePeriodAppearance.Auto
		])
	}

	get periodLabel() {
		const period = this.props.value.period
		const value = this.periodOptions
			.concat(this.customDateRangeOptions)
			.find(x => x.value == period)
		return value?.label
	}

	destroy() {
		this.mobx?.destroy()
	}
}

export type EditableComponentType<T> = {
	value?: T
	onChange?: (value: T) => void
}

export type WithUncontrolledValueProps<T> = {
	setValueCallback: (setValue: (value: T) => void) => void
}

export const withUncontrolledValue = memoize(<T, P extends EditableComponentType<T>>(WrappedComponent: React.ComponentType<P>, extra?: WithUncontrolledValueProps<T>) =>
	(props: P) => {
		const {value, onChange, ...rest} = props;

		const [storedValue, setStoredValue] = React.useState(value)

		const onChangeWrapper = React.useCallback((value: T) => {
			onChange?.(value)
			setStoredValue(value)
		}, [storedValue])


		React.useEffect(() => {
			extra?.setValueCallback?.(setStoredValue)
		}, [setStoredValue])

		return <WrappedComponent value={storedValue} onChange={onChangeWrapper} {...(rest as P)}/>
	}, (WrappedComponent, extra?) => extra?.setValueCallback)

const TimePeriodSelectorContext = React.createContext<TimePeriodSelectorStore>(null);

const DropDownTimePeriodSelector = observer(() => {
	const store = React.useContext(TimePeriodSelectorContext)
	return <div className={b({appearance: 'dropdown'})}>
		<AntSelect
			options={store.periodOptions}
			className={b('selector')}
			size={store.props.size}
			value={store.props.value.period}
			onChange={store.periodChanged}
		/>

		{store.props.value.period == TimePeriodType.Custom && <DateRangePicker/>}
		{store.props.value.period == TimePeriodType.DynamicInterval && <IntervalPicker/>}
	</div>
})
const ButtonsTimePeriodSelector = observer(() => {
	const store = React.useContext(TimePeriodSelectorContext)
	const mod: {[id: string]: boolean} = {};
	mod[store.props.size?.toString() ?? 'middle'] = true;
	const selectorTypeSwitcherTitle = store.selectorMode == SelectorMode.Count
		? i18n('Click to switch to time data selection')
		: i18n('Click to switch to Last n data selection');

	return <div className={b({appearance: 'buttons'})}
	            onClick={store.checkIfNeedToShowCustomSelectors}>

		{store.selectorMode == SelectorMode.Time &&<AntPopover
			placement={"bottom"}
			content={<IntervalPicker/>}
			trigger={"hover"}
			mouseLeaveDelay={1}
			onModal={true}
			open={store.showIntervalSelector}
			onOpenChange={v => !v && (store.showIntervalSelector = v)}
		>
			<AntRadioGroup
				options={store.periodOptions}
				optionType={'button'}
				buttonStyle={'solid'}
				size={store.props.size}
				value={store.props.value.period}
				onChange={store.periodChanged}
			/>
		</AntPopover>}

		{(store.customDateRangeEnabled && store.selectorMode == SelectorMode.Time) &&
			<AntPopover
				placement={"bottom"}
				content={<DateRangePicker/>}
				trigger={"hover"}
				mouseLeaveDelay={1}
				onModal={true}
				open={store.showDateRangeSelector}
				onOpenChange={v => !v && (store.showDateRangeSelector = v || store.dateRangePickerOpened)}
			>
				<span title={i18n('Click to set static time range')}>
					<AntRadioGroup
						size={store.props.size}
						className={b('date-range-button')}
						onChange={store.periodChanged}
						optionType={'button'}
						buttonStyle={'solid'}
						options={store.customDateRangeOptions}
						value={store.props.value.period}
					/>
				</span>
			</AntPopover>
		}

		{store.selectorMode == SelectorMode.Count && <div className={b('cnt-editor', mod)}><LastNPicker/></div>}
		{store.lastNEnabled && <span className={b('cnt-switch-button', mod)}>
			<AntButton size={store.props.size} icon={<RetweetOutlined />} onClick={store.toggleMode} title={selectorTypeSwitcherTitle}></AntButton>
		</span>
		}
	</div>
})

const HoverableTimePeriodSelector = observer(() => {
	const store = React.useContext(TimePeriodSelectorContext)

	return <div className={b({appearance: 'hoverable'})}>
		<AntPopover
			placement={"left"}
			content={<div className={b('hoverable-content')}>
				<ButtonsTimePeriodSelector/>
			</div>}
			trigger={"hover"}
			mouseLeaveDelay={1}
			mouseEnterDelay={0}
			onModal={true}
			open={store.showHoverableTimeSelector}
			onOpenChange={v => store.showHoverableTimeSelector = v || store.anyPopoverOpened}
		>
			<div className={b('hoverable-wrapper')}>
				<AntTag>
					{store.periodLabel}
				</AntTag>
			</div>
		</AntPopover>
	</div>
})

const AutoTimePeriodSelector = observer(() => {
	const [appearance, setAppearance] = React.useState(
		TimePeriodAppearance.Buttons
	);
	const [buttonsWidth, setButttonsWidth] = React.useState(0);
	const [resizeObserver, setResizeObserver] =
		React.useState<ResizeObserver>(null);
	const containerRef = React.useRef<HTMLDivElement>();
	const buttonsRef = React.useRef<HTMLDivElement>();

	useEffect(() => {
		if (!containerRef.current) {
			return;
		}
		const resize = debounce(() => {
			if (
				appearance == TimePeriodAppearance.Buttons &&
				buttonsRef.current?.offsetWidth > containerRef.current.offsetWidth
			) {
				setButttonsWidth(buttonsRef.current.offsetWidth);
				setAppearance(TimePeriodAppearance.Hoverable);
			} else {
				if (containerRef.current.offsetWidth > buttonsWidth) {
					setAppearance(TimePeriodAppearance.Buttons);
				}
			}
		}, 300);
		const observer = new ResizeObserver(resize);
		setResizeObserver(observer);
		observer.observe(containerRef.current);

		return () => {
			resizeObserver?.disconnect();
		};
	}, [containerRef.current, buttonsRef.current]);

	return (
		<div className={b({ appearance: "auto" })} ref={containerRef}>
			{appearance == TimePeriodAppearance.Buttons && (
				<ButtonsTimePeriodSelector ref={buttonsRef} />
			)}
			{appearance == TimePeriodAppearance.Hoverable && (
				<HoverableTimePeriodSelector/>
			)}
		</div>
	);
});

const IntervalPicker = observer(() => {
	const store = React.useContext(TimePeriodSelectorContext)
	return <div className={b('custom-interval-selector')}>
		<AntInputNumber
			value={store.intervalTempValue}
			onChange={store.triggerIntervalValueChanged}
			max={store.maxIntervalValue}
			className={b('custom-interval-value')}
		/>
		<AntSelect
			value={store.intervalTempType}
			onChange={store.triggerIntervalTypeChanged}
			className={b('custom-interval-type')}
			options={getIntervalTypeDataSource()}
		/>
	</div>
})

const DateRangePicker = observer(() => {
	const store = React.useContext(TimePeriodSelectorContext)
	return <AntRangePicker
		value={store.tempDateRange}
		onChange={store.onTempDateRangeChange}
		clearIcon={false}
		onOpenChange={(v) => store.dateRangePickerOpened = v}
	/>
})

const LastNPicker = observer(() => {
	const store = React.useContext(TimePeriodSelectorContext)

	return <>
		<NumberOutlined title={i18n('Last N: Specify the number of most recent data points to display.')} />
		&nbsp;
		<AntSelect className={b('custom-count-selector')}
				   dropdownStyle={{textAlign: 'right'}}
				   value={store.tempLastN}
				   onChange={store.onTempLastNChange}
				   options={store.lastNValueOptions}
				   width={72}
				   size={store.props.size}
				   onSearch={store.onLastNSearch}
			/>
	</>
})


export const TimePeriodSelector = observer((props: TimePeriodSelectorProps) => {
	const [store] = React.useState(() => new TimePeriodSelectorStore(props))

	React.useEffect(() => {
		if(store){
			Object.assign(store.props, props)
		}
	}, [props.value, props.periods, props.appearance, props.onChange, props.size, props.onModal, store])

	if(store == null)
		return null

	const content = () => {
		switch(store.appearance) {
			case TimePeriodAppearance.DropDown:
				return <DropDownTimePeriodSelector/>
			case TimePeriodAppearance.Buttons:
				return <ButtonsTimePeriodSelector/>
			case TimePeriodAppearance.Hoverable:
				return <HoverableTimePeriodSelector/>
			case TimePeriodAppearance.Auto:
				return <AutoTimePeriodSelector/>
		}
	}
	return <TimePeriodSelectorContext.Provider value={store}>
		{content()}
		</TimePeriodSelectorContext.Provider>
})

export type RenderIntoLegacyArgs = {
	periods?: TimePeriodType[]
	valueHolder: TimePeriod
	size?: AntSizeType
	container: HTMLElement
	onChange: (value: TimePeriod) => void
	dynamicOnly?: boolean
	setValueCallback?: (setValue: (value: TimePeriod) => void ) => void
	appearance?: TimePeriodAppearance
	root?: Root
}

export const legacyPeriods = [TimePeriodType.LastHour, TimePeriodType.Last24Hours,
	TimePeriodType.Last7Days, TimePeriodType.Last30Days, TimePeriodType.DynamicInterval,
	TimePeriodType.Custom]

export const legacyPeriodsDynamicOnly = [TimePeriodType.LastHour, TimePeriodType.Last24Hours,
	TimePeriodType.Last7Days, TimePeriodType.Last30Days, TimePeriodType.DynamicInterval]

export function renderIntoLegacy(options: RenderIntoLegacyArgs ){
	const periods = options.periods ?? ( options.dynamicOnly ? legacyPeriodsDynamicOnly : legacyPeriods)

	const Component = withUncontrolledValue<TimePeriod, TimePeriodSelectorProps>(TimePeriodSelector, {
		setValueCallback: options.setValueCallback
	})

	const value = options.valueHolder?.period
		? {
			period: options.valueHolder.period,
			startDate: options.valueHolder.startDate,
			endDate: options.valueHolder.endDate,
			intervalType: options.valueHolder.intervalType,
			intervalValue: options.valueHolder.intervalValue,
			lastNValue: options.valueHolder.lastNValue,
		}
		:{
			period: TimePeriodType.Last24Hours
		}

	const root = options.root ?? createRoot(options.container)
	root.render(<Component
		periods={periods}
		size={options.size}
		appearance={options.appearance ?? TimePeriodAppearance.Buttons}
		value={value}
		onChange={options.onChange}
	/>)

	return [value, root]
}

export function areTheSame(lhv: TimePeriod, rhv: TimePeriod){
	if(lhv == rhv){
		return true
	}

	if(lhv == null || rhv == null){
		return false
	}

	return lhv.period == rhv.period
		&& lhv.endDate == rhv.endDate
		&& lhv.startDate == rhv.startDate
		&& lhv.intervalType == rhv.intervalType
		&& lhv.intervalValue == rhv.intervalValue
		&& lhv.lastNValue == rhv.lastNValue
}

export function minTimePeriodForTimeStamp(timestamp: number) {
	let secondsSinceTheLastEvent = (new Date().getTime() - timestamp) / 1000;

	if (secondsSinceTheLastEvent < 60 * 60)
		return TimePeriodType.LastHour
	if (secondsSinceTheLastEvent < 60 * 60 * 24)
		return TimePeriodType.Last24Hours
	if (secondsSinceTheLastEvent < 60 * 60 * 24 * 7)
		return TimePeriodType.Last7Days;

	return TimePeriodType.Last30Days
}
