import {Localization} from "core/localization/localization";

export const sharedDictionary = new Localization({
  "Click to see Health reasons": {
    "no": "Klikk for å se helseårsaker",
    "en": "Click to see health reasons"
  },
	"Click to see events": {
	  no: "Klikk for å se hendelser"
	}
});
