import React from "react";
import Highcharts, {Chart, DrilldownEventObject, DrillupEventObject, Options} from "highcharts";
import {RulesConfiguration} from "controls/queryBuilder/ruleDefinition";
import {apiFetch, ApiRequest, PagedList} from "framework/api";
import {GridDataItem} from "controls/grid/gridDataItem";
import {TimePeriod} from "controls/react/form/timePeriodSelector";
import {TimePeriodType} from "controls/react/form/timePeriodType";
import {GridStore} from "controls/grid/gridStore";
import {WidgetProps} from "controls/designer/features/widgets/widgetProps";
import {makeAutoObservable, toJS} from "mobx";
import {debounce, throttle} from "lodash";
import Configuration from "configuration";
import {formatNumber} from "tools/helpers/math";
import {ModalPosition, openModal} from "controls/react/ant/antModal";
import {getCostBarChartColor} from "controls/designer/features/widgets/cost/budget/chartDefaultColors";
import {
	DataSource,
	getDataSourceData,
	getDataSourceFiltersConfiguration,
	getDataSources
} from "controls/designer/features/widgets/datasources/dataSourceGridWidget";
import {MobxManager, ModelValidator} from "framework/mobx-integration";
import {WidgetConfig, WidgetConfigurationProps} from "controls/designer/features/widgets/widgetConfig";
import {sharedLocalization} from "controls/designer/features/widgets/localization";
import {
	DrilledDownModal,
	DrilledDownWidgetStore
} from "controls/designer/features/widgets/datasources/datasourceDrilleddownGrid";
import {
	DatasourceSelectionConfig,
	SelectionMode
} from "controls/designer/features/widgets/datasources/datasourceSelectionConfig";
import {ColorResult} from "react-color";

export const i18n = require('core/localization').translator({
	'Select the desired columns from datasource. Only the selected columns will appear in the chart, allowing for customized data visualization.': {
		no: 'Velg de ønskede kolonnene fra datakilden. Bare de valgte kolonnene vil vises i diagrammet, noe som gir mulighet for tilpasset datavisualisering.'
	},
	'Category: Used to logically group and organize data in the chart for better visualization.': {
		no: 'Kategori: Brukes til å logisk gruppere og organisere data i diagrammet for bedre visualisering.'
	},
	'Data Columns: Select columns from Datasource to be displayed in the chart. Only integer and double columns are supported.': {
		no: 'Datakolonner: Velg kolonner fra datakilde som skal vises i diagrammet. Bare tall kolonner støttes.'
	},
	'Data rows: Select rows from Datasource to be displayed in the chart.': {
		no: 'Datarader: Velg rader fra datakilde som skal vises i diagrammet.'
	},
	'Pie': {no: 'Pie'},
	'3D Pie': {no: '3D Pie'},
	'Vertical': {no: 'Vertikal'},
	'Horizontal': {no: 'Horisontal'},
	'Chart': {no: 'Diagram'},
	'Chart type': {no: 'Diagramtype'},
	'Headers column': {no: 'Headers column'},
	'Bar chart': {no: 'Søylediagram'},
	'Pie chart': {no: 'Sektordiagram'},
	'Vertical stacked': {no: 'Vertikalt stablet'},
	'Horizontal stacked': {no: 'Horisontalt stablet'},
	'Donut': {no: 'Smultring'},
	'Pivot to Data rows': {no: 'Roter til datarader'},
	'Display': {no: 'Visning'},
	'Time selector': {no: 'Tidsvalg'},
	'Data rows': {no: 'Datarader'},
	'Data columns': {no: 'Datakolonner'}
}, sharedLocalization)

const getChartTypeOptions = () => {
	return [
		{label: i18n('Bar chart'), value: 'bar'},
		{label: i18n('Pie chart'), value: 'pie'}
	]
}

const getPieSubTypeOptions = () => {
	return [
		{label: i18n('Pie'), value: '2d-pie', type: 'pie'},
		{
			label: i18n('3D Pie'),
			value: '3d-pie',
			type: 'pie',
			chart3DOptions: {
				enabled: true,
				alpha: 45,
				beta: 0
			}
		},
		{
			label: i18n('Donut'),
			value: 'donut',
			type: 'pie',
			series: {innerSize: '60%'}
		},
	]
}

const getBarSubTypeOptions = () => {
	return [
		{label: i18n('Vertical'), value: 'column', type: 'column'},
		{label: i18n('Horizontal'), value: 'bar', type: 'bar'},
		{label: i18n('Vertical stacked'), value: 'column-stacked', type: 'column'},
		{label: i18n('Horizontal stacked'), value: 'bar-stacked', type: 'bar'},
	]
}

export type ColorRow = {name: string, color: ColorResult, key: string};
export class DataSourcesChartWidgetConfig implements Omit<WidgetConfig, 'accountId'> {
	id: string
	type: string
	title: string
	dataSourceId: string
	accountId: string
	chartType: string
	columns: string[]
	rows: string[]
	chartSubType: string
	legendColumn: string
	legendColors: ColorRow[]
	decimalsNumber: number
	pivotData: boolean
	period: TimePeriod
	hideTimePeriodSelector: boolean

	validator = new ModelValidator(this)
	mobx = new MobxManager();

	constructor(model: DataSourcesChartWidgetConfig) {
		Object.assign(this, model);
		makeAutoObservable(this);
		this.validator
			.required('title')
			.required('dataSourceId')
			.required('accountId')
			.required('chartType')
			.required('chartSubType')
			.required('legendColumn');

		this.mobx.reaction(x => this.accountId, () => this.dataSourceId = undefined);
		this.mobx.reaction(x => this.dataSourceId, () => {
			this.columns = [];
			this.rows = [];
			this.legendColumn = undefined;
		});
		this.mobx.reaction(x => this.chartType, x => this.chartSubType = undefined);
	}

	getConfig() {
		const {mobx, validator, ...config} = toJS(this);
		return config;
	}

	destroy() {
		this.validator.destroy();
		this.mobx.destroy();
	}
}

export class DataSourcesChartWidgetStore {
	loading: boolean = false;
	noDataAvailable: boolean = false;
	errorMessage: string;
	chartContainerRef = React.createRef<HTMLDivElement>();
	chart: Highcharts.Chart;
	dataBuilders: { [id: string]: (e?: DrilldownEventObject) => Promise<any[]> } = {}
	drillDownRequestPayloadBuilders: { [id: string]: (key: string, e: DrilldownEventObject) => {drillDownField: string, drillDownData: {[key: string]: any}} } = {}
	datasourceRuleConfig: RulesConfiguration;
	data: PagedList<GridDataItem<any>>;

	drilledDownDatasourceRuleConfig: RulesConfiguration[] = []
	drilledDownData: PagedList<GridDataItem<any>>[] = []

	gridStore: GridStore<any>

	initialized: boolean = false;

	get categoryColumn() {
		return this.props.config.pivotData
			? 'rowHeader'
			: this.props.config.legendColumn || 'rowHeader';
	}

	get timePeriod() {
		return this.props.config.period;
	}
	set timePeriod(value: TimePeriod) {
		this.props.config.period = value;
	}

	private subTypes: any[];

	constructor(private props: WidgetProps<DataSourcesChartWidgetConfig>) {
		this.dataBuilders['column'] = this.buildBarData
		this.dataBuilders['bar'] = this.buildBarData
		this.dataBuilders['pie'] = this.buildPieData
		this.drillDownRequestPayloadBuilders['column'] = this.buildBarDataPayload
		this.drillDownRequestPayloadBuilders['bar'] = this.buildBarDataPayload
		this.drillDownRequestPayloadBuilders['pie'] = this.buildPieDataPayload
		this.subTypes = [...getPieSubTypeOptions(), ...getBarSubTypeOptions()];
		this.props.config.period ??= {period: TimePeriodType.Last24Hours}
		makeAutoObservable(this);
	}

	init = async () => {
		await this.fixLegacyConfig();
		const confRequest = this.getDataSourceFiltersConfiguration();
		const dataRequest = this.getDataRequest();

		const datasourceRuleConfig = await apiFetch(confRequest);
		const data = await apiFetch(dataRequest);

		if (datasourceRuleConfig.success && data.success) {
			this.datasourceRuleConfig = datasourceRuleConfig.data;
			this.data = data.data;
			await this.drawChart();
		} else {
			this.errorMessage = [datasourceRuleConfig.message, data.message].filter(x => !!x).join(';\r\n');
		}
		this.initialized = true;
	}

	fixLegacyConfig = async () => {
		if (this.props.config.columns?.length && this.props.config.rows?.length) {
			return;
		}
		const confRequest = this.getDataSourceFiltersConfiguration();
		this.restoreInitialPayload(confRequest.payload);
		const dataRequest = this.getDataRequest();
		this.restoreInitialPayload(dataRequest.payload);

		const datasourceRuleConfig = await apiFetch(confRequest);
		const data = await apiFetch(dataRequest);

		if (!this.props.config.columns?.length) {
			this.props.config.columns = Object.keys(datasourceRuleConfig.data)
				.filter(key => datasourceRuleConfig.data[key].type == "integer");
		}
		if (!this.props.config.rows?.length) {
			this.props.config.rows = data.data.items.map(item => item.data[this.categoryColumn])
		}
	}

	restoreInitialPayload = (payload: {pivotData: boolean, dataRows: string[], dataColumns: string[]}) => {
		payload.pivotData = false;
		delete payload.dataRows;
		delete payload.dataColumns;
	}

	drawChart = async () => {
		const series = await this.dataBuilders[this.props.config.chartType]?.() ?? []

		series.forEach(x => Object.assign(x, this.seriesDefaults))

		const categoryKey = Object.keys(this.datasourceRuleConfig).find(key => this.datasourceRuleConfig[key].label === this.categoryColumn);
		const categories = this.data.items.map(row => row.data[categoryKey ?? this.categoryColumn]);
		this.chart = new Highcharts.Chart(this.chartContainerRef.current, this.commonChartSettings(series, categories));
		//workaround to remove all option color. Seems like highcharts does not support that out of the box
		$(this.chartContainerRef.current).find('.chart-all-option').closest('g').find('rect').remove();
		$(this.chartContainerRef.current).find('.highcharts-3d-frame').closest('g').remove();
	}

	onPeriodChange = debounce((period: TimePeriod) => {
		this.timePeriod = period;
		this.chart?.destroy();
		this.init();
	}, 1000)

	get chartType() {
		return this.subTypeData?.type ?? 'column';
	}

	get options3d() {
		return this.subTypeData?.chart3DOptions;
	}

	get subTypeData() {
		return this.subTypes.find(x => x.value === this.props.config.chartSubType);
	}

	get seriesDefaults(){
		return this.subTypeData?.series ?? {}
	}

	destroy() {
		this.chart?.destroy();
	}

	commonChartSettings = (chartSeries: any[], categories: string[]): Options => {
		const store = this;
		return {
			chart: {
				type: this.chartType,
				options3d: this.options3d,
				backgroundColor: null,
				events: {
					drilldown: function (this: Chart, e: DrilldownEventObject) {
						// store.drilledDownDatasourceRuleConfig.push(store.datasourceRuleConfig)
						// store.drilledDownData.push(store.data)
						// store.onDrillDown(this, e);
						store.onDrillDownGridEditionThrottled(this, e);
					},
					// invokes for every series item, but we need only one
					drillup: throttle(function(this: Chart, e: DrillupEventObject) {
						// store.datasourceRuleConfig = store.drilledDownDatasourceRuleConfig.pop();
						// store.data = store.drilledDownData.pop();
					}, 500, {trailing: false})
				}
			},
			title: {
				text: null
			},
			credits: {
				enabled: false
			},
			exporting: Configuration.highcharts.exporting,
			legend: {
				enabled: true,
				align: 'right',
				verticalAlign: 'top',
				backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || 'none',
				shadow: false,
				labelFormatter: function () {
					return `${this.name}`;
				}
			},
			xAxis: {
				categories: categories,
			},
			yAxis: {
				title: {text: ''}
			},
			tooltip: {
				backgroundColor: 'white',
				borderWidth: 0,
				shadow: {
					offsetX: 0,
					offsetY: 0,
					opacity: 1,
					width: 16,
					color: 'rgb(0,0,0,0.01)'
				},
				useHTML: true,
				headerFormat: '<b>{point.x}</b><br/>',
				formatter: function (e) {
					return `${this.point.name ?? this.series.name}<br/>
					${i18n('Value')}: ${formatNumber(this.point.y, store.props.config.decimalsNumber,)}`;
				}
			},
			plotOptions: {
				bar: {
					pointWidth: 20,
					groupPadding: 0.001,
				},
				pie: {
					allowPointSelect: true,
					cursor: 'pointer',
					depth: 35,
					dataLabels: {
						enabled: true
					},
					showInLegend: true
				},
				column: {
					stacking: this.chartType == 'pie' ? undefined : 'normal',
					dataLabels: {
						formatter: function (e) {
							let formattedNumber = formatNumber(this.y, store.props.config.decimalsNumber,);
							if (
								parseInt(formattedNumber) === 0 &&
								formattedNumber % 1 === 0
							) {
								return null;
							} else {
								return formattedNumber;
							}
						},
						style: {
							textOutline: 'none'
						}
					}
				},
				series: {
					stacking: this.chartType == 'pie' ? undefined : 'normal',
					dataLabels: {
						formatter: function (e) {
							let formattedNumber = formatNumber(this.y, store.props.config.decimalsNumber,);
							if (
								parseInt(formattedNumber) === 0 &&
								formattedNumber % 1 === 0
							) {
								return null;
							} else {
								return formattedNumber;
							}
						},
						style: {
							textOutline: 'none'
						}
					}
				},
			},
			series: chartSeries,
			drilldown: {
				drillUpButton: {
					relativeTo: 'spacingBox',
					position: {
						align: 'left',
						y: 0,
						x: 0
					}
				}
			},
			lang: {
				drillUpText: i18n('Back')
			}
		}
	}

	onDrillDownGridEditionThrottled = throttle((chart: Chart, e: DrilldownEventObject) => {
		this.onDrillDownGridEdition(chart, e);
	}, 300, {trailing: false})

	onDrillDownGridEdition = async (chart: Chart, e: DrilldownEventObject) => {
		let key = Object.keys(this.datasourceRuleConfig)
			.filter(key => this.datasourceRuleConfig[key].type == "integer")
			.find(key => this.datasourceRuleConfig[key].label == (e.point.name ?? e.point.series.name)) ?? e.point.options.name.toString();

		const drillDownStore = new DrilledDownWidgetStore(this, this.props.config, key, e);
		await openModal({
			title: '',
			width: 1000,
			height: 500,
			resizable: true,
			defaultFooterButtons: [],
			mask: false,
			positionType: ModalPosition.Mouse,
			footer: null
		}, <DrilledDownModal store={drillDownStore} />);
	}

	onDrillDown = async (chart: Chart, e: DrilldownEventObject) => {
		let key = Object.keys(this.datasourceRuleConfig)
			.filter(key => this.datasourceRuleConfig[key].type == "integer")
			.find(key => this.datasourceRuleConfig[key].label == e.point.name);

		const confRequest = this.getDataSourceFiltersConfiguration(key, e);
		this.datasourceRuleConfig = (await apiFetch(confRequest))?.data ?? JSON.parse(JSON.stringify(this.datasourceRuleConfig));

		const dataRequest = this.getDataRequest(key, e);
		this.data = (await apiFetch(dataRequest))?.data;

		const chartData = await this.dataBuilders[this.props.config.chartType]?.(e);
		const series = chartData.find(x => x.name == e.point.series.name);
		chart.addSeriesAsDrilldown(e.point, series);
	}

	buildPieData = async (e?: DrilldownEventObject) => {
		const barColorFn = getCostBarChartColor('BLUE');
		let keys = Object.keys(this.datasourceRuleConfig)
			.filter(key => this.datasourceRuleConfig[key].type == "integer");

		const seriesData = this.data.items.map((item) => {
			const color = this.props.config.legendColors?.find(x => x.key == item.data[this.categoryColumn])?.color.hex ?? barColorFn.next().value;

			return {
				name: item.data[this.categoryColumn],
				y: keys.reduce((r, key) => {
					return r + (item.data[key] ?? 0)
				}, 0),
				drilldown: true,
				color: color
			};
		})

		const data = {
			type: 'pie',
			colorByPoint: true,
			data: seriesData
		};
		const seriesOptions = this.subTypes.find(x => x.id === this.props.config.chartSubType)?.series;
		if (seriesOptions) {
			Object.assign(data, seriesOptions);
		}
		return [data];
	}

	buildBarData = async (e?: DrilldownEventObject) => {
		const barColorFn = getCostBarChartColor('BLUE');
		const keys = Object.keys(this.datasourceRuleConfig)
			.filter(key => this.datasourceRuleConfig[key].type == "integer");


		return keys.map((key, colIndex) => {
			const barColor = this.props.config.legendColors?.find(x => x.key == key)?.color.hex ?? barColorFn.next().value;
			const sData = this.data.items.map((row, rowIndex) => ({
				y: row.data[key] ?? 0,
				drilldown: true,
				id: rowIndex
			}))
			return {
				name: this.datasourceRuleConfig[key].label,
				data: sData,
				stack: (this.subTypeData?.value ?? '').endsWith('-stacked') ? 'main' : barColor,
				color: barColor
			}
		});
	}

	getDataRequest = (key?: string, e?: DrilldownEventObject) => {
		const dataRequest = getDataSourceData(this.props.config);
		dataRequest.payload = {}
		dataRequest.payload.filter = {}
		dataRequest.payload.sort = []
		dataRequest.payload.skip = 0
		dataRequest.payload.take = 999
		dataRequest.payload.timePeriod = this.timePeriod
		if (!key) {
			dataRequest.payload.dataRows = this.props.config.rows;
			dataRequest.payload.dataColumns = this.props.config.columns;
		}
		const additionalPayload = this.drillDownRequestPayloadBuilders[this.props.config.chartType]?.(key, e)
		Object.assign(dataRequest.payload, additionalPayload);
		return dataRequest;
	}

	getDataSourceFiltersConfiguration = (key?: string, e?: DrilldownEventObject) => {
		const payload: any = {};
		const additionalPayload = this.drillDownRequestPayloadBuilders[this.props.config.chartType]?.(key, e)
		Object.assign(payload, additionalPayload);
		payload.timePeriod = this.timePeriod
		if (!key) {
			payload.dataRows = this.props.config.rows;
			payload.dataColumns = this.props.config.columns;
		}

		return new ApiRequest<RulesConfiguration>({
			url: `dataSources/${this.props.config.dataSourceId}/ruleConfiguration`,
			accountId: this.props.config.accountId,
			accountBased: true,
			method: 'POST',
			payload
		})
	}

	buildPieDataPayload = (key?: string, e?: DrilldownEventObject) => {
		const initial: {[key: string]: any} = {};
		if (e) {
			initial[this.categoryColumn] = e.point.name;
		}

		return {
			drillDownField: '', // because for pie aggregated
			drillDownData: key
				? this.data.items.find(x => x.data[this.categoryColumn] == key)?.data
				: undefined,
			pivotData: this.props.config.pivotData,
			categoryColumn: this.categoryColumn
		};
	}

	buildBarDataPayload = (key?: string, e?: DrilldownEventObject) => {
		return {
			drillDownField: key,
			drillDownData: key ? this.data.items[e.point.x].data : undefined,
			pivotData: this.props.config.pivotData,
			categoryColumn: this.categoryColumn
		};
	}
}

type ColumnOptions = {value: string, label: string}
type ChartTypeOptions = {value: string, label: string, type?: string, chart3DOptions?: any}

export class DataSourcesChartWidgetConfigurationStore {
	columnsOptions: ColumnOptions[];
	rowsOptions: ColumnOptions[];
	legendOptions: ColumnOptions[];
	datasourceRuleConfig: RulesConfiguration;
	chartTypes: ChartTypeOptions[];
	pieSubTypes: ChartTypeOptions[];
	barSubTypes: ChartTypeOptions[];
	dataSources: DataSource[];
	model: DataSourcesChartWidgetConfig;

	get colorColumnOptions() {
		return (this.model.pivotData && this.model.chartType != 'pie') || (!this.model.pivotData && this.model.chartType == 'pie')
			? this.rowsOptions
			: this.columnsOptions;
	}

	get colorColumns() {
		return (this.model.pivotData && this.model.chartType != 'pie') || (!this.model.pivotData && this.model.chartType == 'pie')
			? this.model.rows
			: this.model.columns;
	}

	mobx = new MobxManager();
	constructor(public props: WidgetConfigurationProps<DataSourcesChartWidgetConfig>) {
		const configWithDefaults = Object.assign({chartType: 'bar'}, this.props.config);
		this.model = new DataSourcesChartWidgetConfig(configWithDefaults);
		this.props.setValidator(this.model.validator);
		this.props.setFormConfigUpdater(() => {
			Object.assign(this.props.config, this.model.getConfig());
		});
		makeAutoObservable(this);
		this.mobx.reaction(x => this.model.dataSourceId, () => {
			this.loadColumns();
			this.loadRows();
		});
		this.mobx.reaction(x => this.model.legendColumn, () => {
			this.loadRows();
		});
		this.mobx.reaction(x => this.colorColumns, x => {
			this.model.legendColors = this.model.legendColors.filter(y => {
				return this.colorColumns.includes(this.colorColumnOptions.find(z => z.label == y.name)?.value);
			});
		})
		this.mobx.reaction(x => this.model.pivotData, pivotData => {
			this.model.legendColors = [];
		});
		this.model.period ??= {period: TimePeriodType.Last24Hours};
	}

	init = async () => {
		this.model.decimalsNumber ??= 0;
		await this.loadColumns();
		await this.loadRows();

		this.model.legendColors ??= []
		this.chartTypes = getChartTypeOptions();
		this.pieSubTypes = getPieSubTypeOptions();
		this.barSubTypes = getBarSubTypeOptions();
		this.dataSources = (await apiFetch(getDataSources({accountId: this.model.accountId}))).data;
	}

	editColumns = async () => {
		await openModal({
			title: '',
			width: 1000,
			height: 500,
			resizable: true,
			defaultFooterButtons: [],
			mask: false,
			positionType: ModalPosition.Mouse,
			footer: null,
			closeTriggerExtractor: close => this.closeColumnsEditorGrid = close
		}, <DatasourceSelectionConfig selectionMode={SelectionMode.Columns}
									  dataSourceId={this.model.dataSourceId}
									  accountId={this.model.accountId}
									  categoryColumn={this.model.legendColumn}
									  value={this.model.columns}
									  onChange={this.onColumnsByGridUpdate} />);
	}

	onColumnsByGridUpdate = (values: string[]) => {
		this.model.columns = values;
		this.closeColumnsEditorGrid();
	}
	closeColumnsEditorGrid: () => void;

	editCategoryColumn = async () => {
		await openModal({
			title: '',
			width: 1000,
			height: 500,
			resizable: true,
			defaultFooterButtons: [],
			mask: false,
			positionType: ModalPosition.Mouse,
			footer: null,
			closeTriggerExtractor: close => this.closeCategoryColumnEditorGrid = close
		}, <DatasourceSelectionConfig selectionMode={SelectionMode.CategoryColumn}
									  dataSourceId={this.model.dataSourceId}
									  accountId={this.model.accountId}
									  categoryColumn={this.model.legendColumn}
									  value={[this.model.legendColumn]}
									  onChange={this.onCategoryColumnByGridUpdate} />);
	}

	onCategoryColumnByGridUpdate = (values: string[]) => {
		this.model.legendColumn = values[0];
		this.closeCategoryColumnEditorGrid();
	}
	closeCategoryColumnEditorGrid: () => void;

	editRows = async () => {
		await openModal({
			title: '',
			width: 1000,
			height: 500,
			resizable: true,
			defaultFooterButtons: [],
			mask: false,
			positionType: ModalPosition.Mouse,
			footer: null,
			closeTriggerExtractor: close => this.closeRowsEditorGrid = close
		}, <DatasourceSelectionConfig selectionMode={SelectionMode.Rows}
									  dataSourceId={this.model.dataSourceId}
									  accountId={this.model.accountId}
									  categoryColumn={this.model.legendColumn}
									  value={this.model.rows}
									  onChange={this.onRowsByGridUpdate} />);
	}

	onRowsByGridUpdate = (values: string[]) => {
		this.model.rows = values;
		this.closeRowsEditorGrid();
	}
	closeRowsEditorGrid: () => void;

	loadColumns = async () => {
		if (!this.model.dataSourceId || !this.model.accountId) {
			this.columnsOptions = []
			return;
		}
		const datasourceRuleConfig = await apiFetch(getDataSourceFiltersConfiguration(this.model));
		if (datasourceRuleConfig.success) {
			this.datasourceRuleConfig = datasourceRuleConfig.data;

			this.columnsOptions = Object.keys(datasourceRuleConfig.data)
				.filter(key => datasourceRuleConfig.data[key].type == "integer")
				.map(x => ({
					label: datasourceRuleConfig.data[x].label,
					value: x
				}));

			this.legendOptions = Object.keys(datasourceRuleConfig.data)
				.filter(key => datasourceRuleConfig.data[key].type == "text" && key != 'id')
				.map(x => ({
					label: datasourceRuleConfig.data[x].label || (x == 'rowHeader' ? 'Default (rowHeader)' : ''),
					value: x
				}));
		} else {
			this.columnsOptions = []
		}
	}

	loadRows = async () => {
		if (!this.model.dataSourceId || !this.model.accountId || !this.model.legendColumn) {
			this.rowsOptions = []
			return;
		}
		const data = (await apiFetch(this.getDataRequest())).data;
		this.rowsOptions = data.items.map(item => ({
			label: item.data[this.model.legendColumn],
			value: item.data[this.model.legendColumn],
		}))
		if (!this.model.rows?.length) {
			this.model.rows = this.rowsOptions.map(x => x.value);
		}
	}

	getDataRequest = () => {
		const dataRequest = getDataSourceData(this.model);
		dataRequest.payload = {}
		dataRequest.payload.filter = {}
		dataRequest.payload.sort = []
		dataRequest.payload.skip = 0
		dataRequest.payload.take = 999
		return dataRequest;
	}

	destroy() {
		this.mobx.destroy();
		this.model.destroy();
	}
}

export const DataSourcesChartWidgetConfigurationContext = React.createContext<DataSourcesChartWidgetConfigurationStore>(null);
