import {makeAutoObservable, observable} from "mobx"
import {Root} from "react-dom/client";
import React from "react";

import {RedirectConfig} from "controls/designer/features/redirectOnClick/redirectConfig";
import {WidgetConfig} from "controls/designer/features/widgets/widgetConfig";
import {MxCell} from "controls/designer/mxGraphInterfaces";
import {WidgetsWizard} from "controls/designer/features/widgets/widgetForm";
import {createContainer} from "controls/react/ant/antModal";
import {DesignerStore} from "controls/designer/designerStore";
import {getWidgetDescription, WidgetDescription} from "controls/designer/features/widgets/allWidgets";
import {loadDesignerSettings, updateDesignerSetting} from "controls/designer/shared";
import {newGuid} from "tools/guid";
import {checkCellForWidget, destroyWidget} from "controls/designer/features/widgets/tools";
import {sharedLocalization} from "controls/designer/features/widgets/localization";
import {RedirectType} from "controls/designer/features/redirectOnClick/redirectType";
import {RedirectModifier, RedirectPopup} from "tools/ceeviewNavigator";
import {ModelValidator} from "framework/mobx-integration";
import {executeUpdate} from "controls/designer/utils";

const i18n = require('core/localization').translator({}, sharedLocalization)

export type WidgetWizardStoreProps = {
	cell: MxCell
	store: DesignerStore
} | {
	widgetType: string
	store: DesignerStore
}

class RedirectConfigModel implements RedirectConfig{
	type: RedirectType
	modifier?: RedirectModifier
	link?: string
	accountId?: string
	dashboardId?: string
	popup: RedirectPopup

	validator = new ModelValidator(this)
	constructor(config: RedirectConfig) {
		Object.assign(this, config);
		makeAutoObservable(this);
		this.validator
			.required('accountId', () => this.type == RedirectType.Dashboard)
			.required('dashboardId', () => this.type == RedirectType.Dashboard)
	}

	get isValid() {
		return this.validator.valid;
	}

	toConfig() {
		return {
			type: this.type,
			modifier: this.modifier,
			link: this.link,
			accountId: this.accountId,
			dashboardId: this.dashboardId,
			popup: this.popup
		}
	}

	destroy() {
		this.validator.destroy();
	}
}

export class WidgetWizardStore{
	cell: MxCell
	redirectConfig: RedirectConfigModel
	widgetConfig: WidgetConfig
	formValidator: ModelValidator<any>;
	formConfigUpdater: () => void

	root: Root

	lastSavedWidgetType: string

	constructor(public designerStore: DesignerStore) {
		makeAutoObservable(this)

		this.init()
	}

	async init(){
		const lastSavedWidgetType = await loadDesignerSettings('lastSavedWidgetType', 'service-preview');
		let type = lastSavedWidgetType ?? 'service-preview';
		if (!getWidgetDescription(type)) {
			type = 'service-preview';
		}
		this.changeType(type)
	}

	changeType(type: string){
		this.formValidator = undefined;
		this.formConfigUpdater = undefined;
		this.widgetDescription = getWidgetDescription(type)
		this.widgetConfig = JSON.parse(JSON.stringify(this.widgetDescription.defaultConfig))

		this.redirectConfig = new RedirectConfigModel(this.designerStore.redirectConfigsManager.getDefaultConfig());
	}

	_widgetDescription: WidgetDescription<any>
	get widgetDescription(){
		return this._widgetDescription
	}

	//"form" is a constructor-function, mobx wrongly detects it is just a function and converts to an action
	//so new form() stops working
	set widgetDescription(value: WidgetDescription<any>){
		this._widgetDescription = observable(value, {
			form: observable.ref
		})
	}

	get mode(){
		return this.cell != null ? 'update' : 'create'
	}

	get title(){
		if(this.cell != null){
			return this.widgetDescription?.fullTitle || ''
		}else{
			return i18n('Add widget')
		}
	}

	get width(){
		if(this.cell != null){
			return 870
		}else{
			return 1170
		}
	}

	openCreateWidgetWizard(){
		this.cell = null
		this.changeType(this.widgetConfig.type)
		this.openWizard()
	}

	openEditWidgetWizard(cell: MxCell){
		this.cell = cell
		this.redirectConfig = new RedirectConfigModel(this.designerStore.redirectConfigsManager.getEffectiveConfig(cell))
		this.widgetConfig = this.designerStore.widgetsManager.getEffectiveConfig(cell)
		this.widgetDescription = getWidgetDescription(this.widgetConfig.type)
		this.openWizard()
	}

	private openWizard(){
		this.root?.unmount()

		const [root] = createContainer()
		this.root = root;

		this.root.render(
			<WidgetsWizard store={this}/>
		)
	}

	saveWidget(config: WidgetConfig) {
		const designer = this.designerStore.legacyDesigner

		//if widget is legacy then it will pass a copy of config into the method. If a form uses mobx then config's instance will be this.widgetConfig
		config = config ?? this.widgetConfig

		let containerStyle = typeof this.widgetDescription.containerStyle == 'function'
			? this.widgetDescription.containerStyle(config)
			: this.widgetDescription.containerStyle

		let currentStyle = this.cell ? designer.graph.getCellStyle(this.cell) : null

		const strokeColor = containerStyle?.noBorder == true
			? 'none'
			: (currentStyle ? (currentStyle.strokeColor ?? 'none') : '#E6E6E6')

		const fillColor = containerStyle?.transparent
			? 'none'
			: (currentStyle ? (currentStyle.fillColor ?? 'none') : '#FFFFFF')

		const widgetHeader = containerStyle?.header ?? 'default'

		if (!this.cell) {
			const width = this.widgetDescription.width ?? Math.max(this.widgetDescription.minWidth ?? 0, designer.dashboardSettings.widgetWidth)
			const height = this.widgetDescription.height ?? designer.dashboardSettings.widgetHeight

			this.cell = designer.graph.insertVertex(designer.graph.getDefaultParent(), null, null,
				50, 50, width, height,
				`shape=htmlContainerShape;verticalLabelPosition=bottom;verticalAlign=top;containerId=${newGuid()};strokeColor=${strokeColor};fillColor=${fillColor};widgetHeader=${widgetHeader}`
			);
		} else {
			executeUpdate(designer.graph, () => {
				designer.graph.setCellStyles("strokeColor", strokeColor, [this.cell])
				designer.graph.setCellStyles("fillColor", fillColor, [this.cell])
				designer.graph.setCellStyles("widgetHeader", widgetHeader, [this.cell])
			})
		}

		let customData = this.designerStore.getOrCreateCustomData(this.cell)
		customData.widgetConfig = config
		customData.redirectConfig = this.redirectConfig.toConfig();

		destroyWidget(designer, this.cell);
		checkCellForWidget(designer.graph, this.cell);
	}

	closeWizard(){
		this.root.unmount()
		this.cell = null
	}

	destroy(){
		this.root?.unmount()
		this.redirectConfig?.destroy();
		updateDesignerSetting('lastSavedWidgetType', this.widgetConfig.type);
	}
}
