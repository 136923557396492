import React from "react";
import {observer} from "mobx-react";

import {sharedLocalization} from "controls/designer/features/widgets/localization";
import {ApplicationState} from "framework/applicationState";
import {WidgetProps} from "controls/designer/features/widgets/widgetProps";
import {DefaultWidgetWrapper} from "controls/designer/features/widgets/defaultWidgetWrapper";
import {WidgetConfig, WidgetConfigurationProps} from "controls/designer/features/widgets/widgetConfig";
import {GridStore, linkGridAdditionalPayload} from "controls/grid/gridStore";
import {RemoteDataProvider} from "controls/grid/remoteDataProvider";
import {getEventSummaryFilters} from "areas/summary/events/api";
import {TimePeriodAppearance, TimePeriodSelector} from "controls/react/form/timePeriodSelector";
import {GridPlugin, GridSelectionType} from "controls/grid/gridConfiguration";
import {EventsSummaryStore} from "areas/summary/events/eventsSummaryStore";
import {EventsSummaryEntry} from "areas/summary/events/eventsSummaryEntry";
import {Grid} from "controls/grid/grid";
import {DataSizeIndicator} from "controls/grid/dataSizeIndicator";
import {GridSearchInput} from "controls/grid/gridSearchInput";
import {ViewSelector} from "controls/grid/viewManager/viewSelector";
import {EventsTimePeriods} from "areas/summary/events/eventsSummaryPage";
import {Toolbar} from "controls/react/layout/toolbar";
import {Section} from "controls/react/layout/section";
import {deserialize, serialize} from "serializr";
import {GridState} from "controls/grid/gridState";
import {convertLegacyState, LegacyGridWidgetPersistedState} from "controls/grid/legacyGridState";
import {RefreshTimer} from "controls/grid/refreshTimer";
import {FormEntry} from "controls/react/form";
import {AntInput} from "controls/react/ant/antInput";
import {AntCheckbox} from "controls/react/ant/antCheckbox";
import {apiFetch} from "framework/api";
import AccountDropDown from "controls/react/dropdowns/accountDropDown";
import {TagsSelect} from "controls/react/tagsSelect";
import {ExternalStateProvider} from "controls/grid/stateProviders";
import {GridMenuButton} from "controls/grid/plugins/grid-menu/gridMenuButton";
import {CeeviewNavigator} from "tools/ceeviewNavigator";
import {StylesFromMxGraphCellPlugin} from "controls/grid/plugins/stylesFromMxGraphCellPlugin";
import {
	anyFlagSet,
	copyVisibilityFlags,
	GridToolbarVisibilityFlags,
	GridToolbarVisibilityValueLink
} from "areas/service-boards/widgets/grids/gridToolbarVisibility";
import {toJS} from "mobx";

const i = require('core/localization').translator(sharedLocalization,{

});

export function getWidgetDescription(){
	return {
		form: EventsSummaryWidgetConfiguration,
		defaultConfig: {
			type: 'event-summary-grid',
			title: i('Event summary'),
			accountId: ApplicationState.accountId,
			showMenu: true,
			showFilters: true,
			showTimeSettings: true,
			gridToolbarVisibility: {}
		},
		widget: EventsSummaryGridWidget,
		fullTitle: i('Grid') + '/' + i('Event summary'),
		minWidth: 970,
		bypassRedirect: true,
		preProcessConfig: (config: EventsSummaryGridWidgetConfig) => {

			if (config.gridToolbarVisibility == null) {
				config.gridToolbarVisibility = {}
			}
			//@ts-ignore
			//changed in 2.18, need to copy legacy flags to a new set
			copyVisibilityFlags(config, config.gridToolbarVisibility)

			return config
		},
	}
}

export interface EventsSummaryGridWidgetConfig extends WidgetConfig {
	accountId: string
	includeSubaccounts: boolean
	tags: string[]
	showUntagged: boolean
	columns: Record<string, {hidden: boolean}>
	serviceIds: string[],
	gridToolbarVisibility: GridToolbarVisibilityFlags
}

export const EventsSummaryWidgetConfiguration = observer((props:WidgetConfigurationProps<EventsSummaryGridWidgetConfig>) => {
	const {configLink} = props;

	return <Section appearance={"none"}
	                contentPadding={false}
	                childrenPadding={true}>
		<Section appearance={"frame"} title={i('Configuration')} childrenPadding={true}>
			<FormEntry label={i('Title')}>
				<AntInput {...configLink.get('title').props}/>
			</FormEntry>
			<FormEntry label={i('Account')}>
				<AccountDropDown {...configLink.get('accountId').props}/>
			</FormEntry>
			<FormEntry>
				<AntCheckbox valueLink={configLink.get('includeSubaccounts')}>{i('Include subaccounts')}</AntCheckbox>
			</FormEntry>
			{props.children}
		</Section>
		<Section appearance={'frame'} title={i('Tags')} childrenPadding={true}>
			<FormEntry label={i('Tags')} vertical={true} width={'fit'}>
				<TagsSelect accountId={configLink.value.accountId}
				            {...configLink.get('tags').props} />
			</FormEntry>
			<AntCheckbox valueLink={configLink.get('showUntagged')}>{i('Show Untagged')}</AntCheckbox>
		</Section>
		<GridToolbarVisibilityValueLink flags={configLink.get('gridToolbarVisibility')}/>
	</Section>
});


export type EventsSummaryGridPersistedState = {
	grid: GridState<EventsSummaryEntry>
} & LegacyGridWidgetPersistedState

export const EventsSummaryGridWidget = observer(
	class InnerWidget extends React.Component<WidgetProps<EventsSummaryGridWidgetConfig, EventsSummaryGridPersistedState>>{
		store: EventsSummaryGridWidgetStore

		constructor(props: WidgetProps<EventsSummaryGridWidgetConfig, EventsSummaryGridPersistedState>) {
			super(props)

			this.store = new EventsSummaryGridWidgetStore(props)
		}

		render() {
			if(!this.store?.gridStore?.selfInitialized)
				return null

			let flags = this.props.config.gridToolbarVisibility

			const editMode = !this.props.dashboardSettings.readOnly

			return (
				<DefaultWidgetWrapper {...this.props} appearance={"none"}>
					<Section appearance="none" height={"fit"}>
						{(anyFlagSet(flags) || editMode) &&
							<Toolbar>
								{(flags.showMenu || editMode) &&
									<GridMenuButton store={this.store.gridStore}/>
								}

								{(flags.showRefreshTimer || editMode)
									&& <RefreshTimer store={this.store.gridStore}/>}

								{(flags.showTimeSettings || editMode)
									&& <TimePeriodSelector periods={EventsTimePeriods}
									                       appearance={TimePeriodAppearance.Buttons}
									                       size={"small"}
									                       {...linkGridAdditionalPayload(this.store.gridStore, "timePeriod")}/>
								}
								{(flags.showFilters || editMode)
									&& <DataSizeIndicator store={this.store.gridStore}/>}

								{(flags.showFilters || editMode)
									&& <GridSearchInput store={this.store.gridStore}/>}

								{(flags.showViewSelector || editMode)
									&& <ViewSelector store={this.store.gridStore} createViewDisabled={!editMode} editViewDisabled={!editMode}/>}
							</Toolbar>
						}
						<Grid store={this.store.gridStore}/>
					</Section>
				</DefaultWidgetWrapper>
			)
		}

		getStateForSaving() {
			return {
				grid: serialize(this.store.gridStore.state)
			};
		}

		componentWillUnmount(){
			this.store?.destroy()
		}
	}
);

type EventsSummaryGridWidgetStoreProps = {
	config: EventsSummaryGridWidgetConfig,
	persistedState: EventsSummaryGridPersistedState,
	navigator: CeeviewNavigator
}

export class EventsSummaryGridWidgetStore extends EventsSummaryStore{
	props: WidgetProps<EventsSummaryGridWidgetConfig, EventsSummaryGridPersistedState>

	constructor(props: WidgetProps<EventsSummaryGridWidgetConfig, EventsSummaryGridPersistedState>) {
		super();

		this.props = props

		this.init()
	}

	async init() {
		const filtersResult = await apiFetch(getEventSummaryFilters())
		if (!filtersResult.success) {
			return
		}
		const filtersConfiguration = filtersResult.data

		let state = null
		if(this.props.persistedState?.grid){
			state = deserialize<GridState<EventsSummaryEntry>>(GridState, this.props.persistedState.grid)
		}else if( this.props.persistedState?.columns){
			state = convertLegacyState(this.getColumns(this.props.navigator), this.props.persistedState, filtersConfiguration, {
				'accountName': 'accountId',
				'monitorClass': 'monitorClassText'
			})
		}

		this.gridStore = new GridStore<EventsSummaryEntry>({
			columns: this.getColumns(this.props.navigator),
			stateProviders: [
				new ExternalStateProvider(state),
			],
			dataProvider: new RemoteDataProvider({
				dataSource: this.getDefaultDatasource(this.props.config),
				filtersSource: getEventSummaryFilters()
			}),
			plugins: [
				this.getAutoReloadPlugin(this.props.config),
				this.getGridMenuPlugin(),
				new StylesFromMxGraphCellPlugin(this.props.designer.store, this.props.cell)
			],
			defaults: {
				payload: this.getCustomPayloadDefaults()
			},
			selection: GridSelectionType.Many
		})
	}
}
