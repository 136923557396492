import './antCheckbox.less'

import React, {useCallback} from "react"
import {Checkbox, CheckboxProps} from "antd"
import {observer} from "mobx-react"

import {captureValueLink} from "controls/react/ant/utils"
import {CheckboxChangeEvent} from "antd/es/checkbox/Checkbox"
import {CheckboxOptionType} from "antd/es/checkbox/Group"


interface AntCheckboxProps extends Omit<CheckboxProps, 'onChange'>{
	valueLink?: any
	invalid?: boolean
	errors?: Array<any>
	value?: boolean
	title?: string
	onChange?: (value: boolean, e: CheckboxChangeEvent) => void
}

export const AntCheckbox:React.FunctionComponent<AntCheckboxProps> = observer(props => {
	let {errors, invalid, value, onChange, ...antNativeProps} = captureValueLink(props);

	if(antNativeProps.checked === undefined && value !== undefined){
		antNativeProps.checked = value;
	}

	const onChangeWrapper = useCallback((e: CheckboxChangeEvent) => {
		onChange && onChange(e.target.checked, e);
	}, [onChange])

	let component;
	if (props.title) {
		component = <span title={props.title}>
			<Checkbox onChange={onChangeWrapper} {...antNativeProps}/>
		</span>
	} else {
		component = <Checkbox onChange={onChangeWrapper} {...antNativeProps}/>
	}

	return component
})

export type AntCheckboxOptionType = CheckboxOptionType
