import './datasourceChartWidget.less';
import {ApplicationState} from "framework/applicationState";
import {observer} from "mobx-react";
import {WidgetConfig, WidgetConfigurationProps} from "controls/designer/features/widgets/widgetConfig";
import {Section, Toolbar} from "controls/react/layout";
import {FormEntryNew} from "controls/react/form/formEntryNew";
import {AntInput} from "controls/react/ant/antInput";
import {AccountSelector} from "controls/react/accountSelector";
import {AntSelect} from "controls/react/ant/antSelect";
import React, {useContext} from "react";
import {WidgetProps} from "controls/designer/features/widgets/widgetProps";
import {DefaultWidgetWrapper} from "controls/designer/features/widgets/defaultWidgetWrapper";
import {BoxView} from "controls/react/layout/boxView";
import b_ from "b_";
import {MenuOutlined, QuestionCircleOutlined} from "@ant-design/icons";
import {ColorResult} from "react-color";
import {LegendColorSettings} from "controls/designer/features/widgets/legendColorSettings";
import {AntInputNumber} from "controls/react/ant/antInputNumber";
import {Link} from "controls/react/link";
import {TimePeriod, TimePeriodAppearance, TimePeriodSelector} from "controls/react/form/timePeriodSelector";
import {TimePeriodType} from "controls/react/form/timePeriodType";
import {AntCheckbox} from "controls/react/ant/antCheckbox";
import {
	DataSourcesChartWidgetConfig,
	DataSourcesChartWidgetConfigurationContext,
	DataSourcesChartWidgetConfigurationStore, DataSourcesChartWidgetStore,
	i18n
} from "controls/designer/features/widgets/datasources/datasourceStores";
import {AntButton} from "controls/react/ant/antButton";
import {linkModel} from "framework/mobx-integration";


const b = b_.with('data-sources-chart');



export function getWidgetDescription() {
	return {
		form: WidgetConfiguration,
		defaultConfig: {
			type: 'data-sources-chart',
			title: i18n('Datasource chart'),
			accountId: ApplicationState.accountId,
			decimalsNumber: 0,
			hideTimePeriodSelector: false
		},
		minWidth: 800,
		widget: DataSourcesChartWidget,
		fullTitle: i18n('Datasource chart'),
	}
}

export const WidgetConfiguration = observer(class InnerConfig extends React.Component<WidgetConfigurationProps<DataSourcesChartWidgetConfig>> {
	store: DataSourcesChartWidgetConfigurationStore;
	constructor(props: WidgetConfigurationProps<DataSourcesChartWidgetConfig>) {
		super(props);
		this.store = new DataSourcesChartWidgetConfigurationStore(props);
	}

	async componentDidMount() {
		await this.store.init();
	}

	componentWillUnmount() {
		this.store?.destroy()
	}
	render() {
		return <DataSourcesChartWidgetConfigurationContext.Provider value={this.store}>
			<Section appearance={"none"}>
				<Section appearance={"frame-top-only"} title={i18n('General')} childrenPadding={true}>
					<FormEntryNew label={i18n('Name')} model={this.store.model} modelField={'title'}>
						<AntInput/>
					</FormEntryNew>
					<FormEntryNew label={i18n('Chart')} model={this.store.model} modelField={'chartType'}>
						<AntSelect nameField={"name"} options={this.store.chartTypes}/>
					</FormEntryNew>
					{this.store.model.chartType == 'pie' &&
						<FormEntryNew label={i18n('Chart type')} model={this.store.model} modelField={'chartSubType'}>
							<AntSelect nameField={"name"} options={this.store.pieSubTypes}/>
						</FormEntryNew>}
					{this.store.model.chartType == 'bar' &&
						<FormEntryNew label={i18n('Chart type')} model={this.store.model} modelField={'chartSubType'}>
							<AntSelect nameField={"name"} options={this.store.barSubTypes}/>
						</FormEntryNew>}
				</Section>
				<Section appearance={"frame-top-only"} title={i18n('Data')} childrenPadding={true}>
					<FormEntryNew label={i18n('Account')} model={this.store.model} modelField={'accountId'}>
						<AccountSelector/>
					</FormEntryNew>
					<FormEntryNew label={i18n('Datasource')} model={this.store.model} modelField={'dataSourceId'}>
						<AntSelect options={this.store.dataSources} nameField={"name"} valueField={'dataSourceId'}/>
					</FormEntryNew>
					<FormEntryNew label={i18n('Category')} model={this.store.model} modelField={'legendColumn'}
								  headerAdditionalContent={<CategoryColumnHeaderAdditional />}>
						<AntSelect options={this.store.legendOptions} />
					</FormEntryNew>
					<FormEntryNew label={i18n('Data columns')}
								  model={this.store.model}
								  modelField={'columns'}
								  headerAdditionalContent={<SelectionHeaderAdditional />}>
						<AntSelect mode={"multiple"} nameField={"name"} options={this.store.columnsOptions} maxTagCount={'responsive'} />
					</FormEntryNew>
					<FormEntryNew label={i18n('Data rows')}
								  model={this.store.model}
								  modelField={'rows'}
								  headerAdditionalContent={<RowHeaderAdditional />}>
						<AntSelect mode={"multiple"} nameField={"name"} options={this.store.rowsOptions} maxTagCount={'responsive'} />
					</FormEntryNew>
					<FormEntryNew label={i18n('Time selector')}
								  model={this.store.model}
								  modelField={'period'}>
						<TimePeriodSelector periods={TimePeriods}
											appearance={TimePeriodAppearance.Buttons} />
						<AntCheckbox {...linkModel(this.store.model, 'hideTimePeriodSelector')}>{i18n('Hide time selector')}</AntCheckbox>
					</FormEntryNew>
				</Section>
				<Section appearance={"frame-top-only"} title={i18n('Display')} childrenPadding={true}>
					<FormEntryNew model={this.store.model} modelField={'pivotData'}>
						<AntCheckbox>{i18n('Pivot to Data rows')}</AntCheckbox>
					</FormEntryNew>
					<FormEntryNew label={i18n('Decimal Numbers')} model={this.store.model} modelField={'decimalsNumber'}>
						<AntInputNumber min={0} max={8} />
					</FormEntryNew>
				</Section>
				<FormEntryNew model={this.store.model} modelField={'legendColors'}>
					<LegendColorSettings legend={this.store.colorColumnOptions}
										 columns={this.store.colorColumns}
										 title={i18n('Color')}/>
				</FormEntryNew>
			</Section>
		</DataSourcesChartWidgetConfigurationContext.Provider>
	}
})

const SelectionHeaderAdditional = observer(() => {
	const context = useContext(DataSourcesChartWidgetConfigurationContext);
	return <>
		<AntButton size={'small'} icon={<MenuOutlined />} onClick={context.editColumns}></AntButton>
		<QuestionCircleOutlined title={i18n('Data Columns: Select columns from Datasource to be displayed in the chart. Only integer and double columns are supported.')}/>
	</>
})

const CategoryColumnHeaderAdditional = observer(() => {
	const context = useContext(DataSourcesChartWidgetConfigurationContext);
	return <>
		<AntButton size={'small'} icon={<MenuOutlined />} onClick={context.editCategoryColumn}></AntButton>
		<QuestionCircleOutlined title={i18n('Category: Used to logically group and organize data in the chart for better visualization.')}/>
	</>
})

const RowHeaderAdditional = observer(() => {
	const context = useContext(DataSourcesChartWidgetConfigurationContext);
	return <>
		<AntButton size={'small'} icon={<MenuOutlined />} onClick={context.editRows}></AntButton>
		<QuestionCircleOutlined title={i18n('Data rows: Select rows from Datasource to be displayed in the chart.')}/>
	</>
})

const TimePeriods = [TimePeriodType.Last24Hours, TimePeriodType.Last7Days,
	TimePeriodType.Last30Days, TimePeriodType.Custom, TimePeriodType.DynamicInterval, TimePeriodType.All, TimePeriodType.LastN];

export const DataSourcesChartWidget = observer(class InnerWidget extends React.Component<WidgetProps<DataSourcesChartWidgetConfig>> {
	store: DataSourcesChartWidgetStore

	constructor(props: WidgetProps<DataSourcesChartWidgetConfig>) {
		super(props)
		this.store = new DataSourcesChartWidgetStore(props)
	}

	render() {
		const timePeriodSelector = this.props.config.hideTimePeriodSelector !== true
			? <TimePeriodSelector periods={TimePeriods}
			                      appearance={TimePeriodAppearance.Buttons}
			                      size={"small"}
			                      value={this.store.timePeriod}
			                      onChange={this.store.onPeriodChange} />
			: null

		return (
			<DefaultWidgetWrapper {...this.props} toolbarAtTheEnd={timePeriodSelector} appearance={"none"}>
				<Section appearance={"none"} height={"fit"} overlaySpinner={this.store.loading}
						 contentOverlay={this.store.loading} contentClass={b()}>
					{this.store.noDataAvailable && <BoxView type={'error'}>{this.store.errorMessage}</BoxView>}
					<Link navigator={this.props.navigator} onClick={() => {}}>
						<div ref={this.store.chartContainerRef} className={b('chart-container')}></div>
					</Link>
				</Section>
			</DefaultWidgetWrapper>
		)
	}

	async componentDidMount() {
		await this.store.init();
	}

	componentWillUnmount() {
		this.store?.destroy()
	}

	onGeometryChanged() {
		if (!this.store.initialized) {
			return;
		}
		this.store.drawChart();
	}
})


