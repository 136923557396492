import { UrlBuilder } from "./../../tools/urlBuilder";
import {translator} from "../../core";

const i = translator({})

export function getDescriptions(){
	return [{
		url: '/integration-wrapper',
		getModuleImport: () => import('./integrationWrapper'),
		react: true,
		routeId: 'integration_wrapper'
	}];
}

export class IntegrationRouter {
	static integrationWrapper({name, url}: {name: string, url: string}) {
		return new UrlBuilder('/integration-wrapper')
			.add('name', name)
			.add('url', url)
			.build()
	}
}
