import {reaction, toJS} from 'mobx'

import {UserSettings} from "tools";
import {addStateStorage} from "controls/react/kendoWrappers/window";
import {newGuid} from "tools/guid";

export function getDesignerWindowState(designer, windowName) {
	const settings = designer.store.settings;

	const autoLayout = designer.config.features.autoLayout
		? settings.autoLayout
		: false;

	if(settings.windows[windowName] == null && settings[windowName] != null) {
		settings.windows[windowName] = settings[windowName];
		delete settings[windowName];
	}

	let state = settings.windows[windowName];

	if (autoLayout || !state ) {
		const autoState = getAutoWindowPositions(windowName);

		autoState.visible = state?.visible ?? true;

		state = autoState;
	}

	if(designer.config.forceHideWindows){
		state.visible = false;
	}

	state.position.top = Math.max(0, state.position.top)
	state.position.left = Math.max(0, state.position.left)

	if(state.position.top + 20 > window.innerHeight || state.position.left + 30 > window.innerWidth){
		let autoState = getAutoWindowPositions(windowName)
		state.position.top = autoState.position.top
		state.position.left = autoState.position.left
	}
	return toJS(state);
}

export async function setDesignerWindowState(designer, windowName, state) {
	designer.store.settings.windows[windowName] = state;
}

export function addDesignerWindowStateStorage(designer, windowName, config){
	config = addStateStorage(config,
		() => getDesignerWindowState(designer, windowName),
		state => setDesignerWindowState(designer, windowName, state)
	);

	let windowRef = null;

	const originalOpen = config.open;
	config.open = e => {
		originalOpen && originalOpen(e);
		windowRef = e.sender;
	}

	reaction(() => designer.store.settings.autoLayout, async (autoLayout) => {
		if(autoLayout){
			designer.store.resetWindowsPositionsTrigger = newGuid()
		}
	})

	reaction(() => designer.store.resetWindowsPositionsTrigger, async () => {
		const state = getAutoWindowPositions(windowName);
		windowRef?.setOptions(state)
	})

	return config;
}

export function getAutoWindowPositions(panel) {
	const $container = $('.geDiagramContainer');
	const $toolbarContainer = $('.geToolbarContainer');
	const containerPos = $container.offset();
	const width = $container.outerWidth();
	const height = $container.outerHeight();
	const defaultSize = getDefaultSize(panel);

	switch(panel) {
		case 'qualifierWindow': {
			const properties = getAutoWindowPositions('properties');

			const top = containerPos.top;
			const left = properties.position.left - defaultSize.width - 20;

			return {
				...defaultSize,
				position: {
					top, left
				}
			}
		}

		case 'properties': {
			const top = containerPos.top + (!$toolbarContainer.height() && 34);
			const left = containerPos.left + width - defaultSize.width - 40;

			return {
				...defaultSize,
				position: {
					top, left
				}
			}
		}


		case 'assets': {
			const propertiesDefaultSize = getDefaultSize("properties");
			const top = containerPos.top;
			const left = containerPos.left + width - defaultSize.width - 40 - propertiesDefaultSize.width - 5;

			return {
				...defaultSize,
				position: {
					top, left
				}
			}
		}

		case 'assetGroups':{
			const propertiesDefaultSize = getDefaultSize("properties");
			const assetsDefaultSize = getDefaultSize("assets");
			const top = containerPos.top;
			const left = containerPos.left + width - defaultSize.width - 40 - propertiesDefaultSize.width - 5 - assetsDefaultSize.width - 5;

			return {
				...defaultSize,
				position: {
					top, left
				}
			}
		}

		case 'shapesCollection': {
			const top = containerPos.top;
			const left = containerPos.left;

			return {
				...defaultSize,
				position: {
					top, left
				}
			}
		}

		case 'qualifiers-viewer': {
			const top = containerPos.top;
			const left = containerPos.left + width - defaultSize.width;

			return {
				...defaultSize,
				position: {
					top, left
				}
			}
		}

		case 'preview-viewer': {
			const {height: qHeight, position: {top: qTop}} = getAutoWindowPositions('qualifiers-viewer');
			const top = qTop + qHeight + 10;
			const left = containerPos.left + width - defaultSize.width;

			return {
				...defaultSize,
				position: {
					top, left
				}
			}
		}

		case 'status-log-viewer': {
			const top = containerPos.top + height - defaultSize.height - 10
			const left = containerPos.left

			return {
				width,
				height: defaultSize.height,
				position: {
					top,
					left
				}
			}
		}

		case 'qualifierDetails': {
			const {width: pWidth, height: pHeight, position: {top: pTop, left: pLeft}} = getAutoWindowPositions('qualifiers-viewer');;

			const position = {
				...defaultSize,
				position: {
					top: pTop,
					left: pLeft - defaultSize.width - 20
				}
			};

			if (position.position.left < 0) {
				position.position.left += defaultSize.width + 20;
				position.position.top += pHeight + 40;
			}

			if (position.position.top + position.height > containerPos.top + height) {
				position.position.top -= pHeight + 40;
				position.position.left += pWidth + 20;
			}
			return position;
		}
	}
}

export function getDefaultSize(panel) {
	switch(panel) {
		case 'qualifierWindow':
			return {
				width: 300,
				height: 540
			}

		case 'assetGroups':
		case 'assets':
			return {
				width: 350,
				height: 540
			}

		case 'properties':
			return {
				width: 300,
				height: 540
			}

		case 'shapesCollection':
			return {
				width: 700,
				height: 400
			}

		case 'qualifiers-viewer':
		case 'preview-viewer':
		case 'status-log-viewer':
			return {
				width: 600,
				height: 250
			}
		case 'qualifierDetails':{
			return {width: 485, height: 720}
		}
	}
}
