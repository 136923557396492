import {readJsonValue} from "controls/designer/dataSourcesManager/dataSourceElement";
import {Cookies} from "../../../core";
import {RedirectType} from "controls/designer/features/redirectOnClick/redirectType";
import {DisplayLabelType, DisplayUnitType, TotalMetricType} from "controls/designer/dataSourcesManager/metricShared";
import {convertLegacyConfig, defaultRedirectConfig} from "controls/designer/features/redirectOnClick/redirectConfig";
import {TimePeriodType} from "controls/react/form/timePeriodType";
import {CssVariables} from "styles/cssVariables";

(function () {

	mxEvent.INSERT_CELLS = 'cellsInserted'
	mxPopupMenu.prototype.zIndex = 120000;
	mxConstants.VERTEX_SELECTION_STROKEWIDTH = 2;
	mxConstants.VERTEX_SELECTION_DASHED = false;

	mxCell.prototype.mxTransient.push('designerElement');

	const zoomOriginal = mxGraph.prototype.zoom
	mxGraph.prototype.zoom = function(factor, center){
		if(this.designer.adaptiveLayout?.algorithmActive === true)
			return

		zoomOriginal.apply(this, arguments)
	}

	mxGraph.prototype.getTooltip = function (state, node, x, y) {
		var deferred = $.Deferred();

		if (state == null)
			return deferred.resolve(null).promise();

		var tip = null;

		// Checks if the mouse is over the folding icon
		if (state.control != null && (node == state.control.node ||
			node.parentNode == state.control.node)) {
			tip = this.collapseExpandResource;
			tip = mxUtils.htmlEntities(mxResources.get(tip) || tip).replace(/\\n/g, '<br>');
		}

		if (tip == null && state.overlays != null) {
			state.overlays.visit(function (id, shape) {
				// LATER: Exit loop if tip is not null
				if (tip == null && (node == shape.node || node.parentNode == shape.node)) {
					tip = shape.overlay.toString();
				}
			});
		}

		if (tip == null) {
			var handler = this.selectionCellsHandler.getHandler(state.cell);

			if (handler != null && typeof(handler.getTooltipForNode) == 'function') {
				tip = handler.getTooltipForNode(node);
			}
		}

		if (tip == null) {
			return this.getTooltipForCell(state.cell);
		}


		return deferred.resolve(tip).promise();
	};


	mxTooltipHandler.prototype.reset = function (me, restart) {
		if (!this.ignoreTouchEvents || mxEvent.isMouseEvent(me.getEvent())) {
			this.resetTimer();

			if (restart && this.isEnabled() && me.getState() != null && (this.div == null ||
				this.div.style.visibility == 'hidden')) {
				var state = me.getState();
				var node = me.getSource();
				var x = me.getX();
				var y = me.getY();
				var stateSource = me.isSource(state.shape) || me.isSource(state.text);

				this.thread = window.setTimeout(mxUtils.bind(this, function () {
					if (!this.graph.isEditing() && !this.graph.popupMenuHandler.isMenuShowing() && !this.graph.isMouseDown) {
						// Uses information from inside event cause using the event at
						// this (delayed) point in time is not possible in IE as it no
						// longer contains the required information (member not found)

						//var tip = this.graph.getTooltip(state, node, x, y);
						var promise = this.graph.getTooltip(state, node, x, y);
						promise.then(tip => {
							this.show(tip, x, y);
							this.state = state;
							this.node = node;
							this.stateSource = stateSource;
						});
					}
				}), this.delay);
			}
		}
	};

	/**
	 * Overrides tooltips to show custom tooltip or metadata.
	 */
	Graph.prototype.getTooltipForCell = function (cell) {
		var tip = cell.value?.getAttribute
			? cell.value.getAttribute('tooltip')
			: "";

		if (tip != null) {
			if (this.isReplacePlaceholders(cell)) {
				tip = this.replacePlaceholders(cell, tip);
			}

			tip = this.sanitizeHtml(tip);
			return Promise.resolve( tip);
		}

		return this.designer.getTooltipForCell(cell);
	};

//we have custom handler, don't need a default one
	EditorUi.prototype.addBeforeUnloadListener = function () {
	};

//changes to make outline behave like preview

	mxOutline.prototype.mode = 'preview';

	mxOutline.prototype.labelsVisible = true;
	/**
	 * Function: init
	 *
	 * Initializes the outline inside the given container.
	 */
	mxOutline.prototype.init = async function (container) {
		this.outline = this.createGraph(container);
		let widgetsModule = null;

		if(this.source.designer.config.features?.widgets) {
			widgetsModule = await import('controls/designer/features/widgets/widgets');
		}

		if(widgetsModule) {
			this.outline.widgets = {};
			this.outline.addListener(mxEvent.REFRESH, () => {
				widgetsModule.findCellsWithWidgets(this.outline);
			});
		}

		// Do not repaint when suspended
		var outlineGraphModelChanged = this.outline.graphModelChanged;
		this.outline.graphModelChanged = mxUtils.bind(this, function (changes) {
			if (!this.suspended && this.outline != null) {
				outlineGraphModelChanged.apply(this.outline, arguments);

				if (this.mode == 'preview') {
					this.outline.fit(null, null, 10);
				}
			}
		});

		// Enables faster painting in SVG
		if (mxClient.IS_SVG && this.mode != 'preview') {
			var node = this.outline.getView().getCanvas().parentNode;
			node.setAttribute('shape-rendering', 'optimizeSpeed');
			node.setAttribute('image-rendering', 'optimizeSpeed');
		}

		// Hides cursors and labels
		this.outline.labelsVisible = this.labelsVisible;
		this.outline.setEnabled(false);


		this.updateHandler = mxUtils.bind(this, function (sender, evt) {
			if (!this.suspended && !this.active) {
				this.update();
				widgetsModule?.findCellsWithWidgets(this.outline);
			}
		});

		// Updates the scale of the outline after a change of the main graph
		this.source.getModel().addListener(mxEvent.CHANGE, this.updateHandler);

		if (this.mode != 'preview') {

			this.outline.addMouseListener(this);

			// Adds listeners to keep the outline in sync with the source graph

			var view = this.source.getView();
			view.addListener(mxEvent.SCALE, this.updateHandler);
			view.addListener(mxEvent.TRANSLATE, this.updateHandler);
			view.addListener(mxEvent.SCALE_AND_TRANSLATE, this.updateHandler);
			view.addListener(mxEvent.DOWN, this.updateHandler);
			view.addListener(mxEvent.UP, this.updateHandler);

			// Updates blue rectangle on scroll
			mxEvent.addListener(this.source.container, 'scroll', this.updateHandler);

			this.panHandler = mxUtils.bind(this, function (sender) {
				if (this.updateOnPan) {
					this.updateHandler.apply(this, arguments);
				}
			});
			this.source.addListener(mxEvent.PAN, this.panHandler);

			// Creates the blue rectangle for the viewport
			this.bounds = new mxRectangle(0, 0, 0, 0);
			this.selectionBorder = new mxRectangleShape(this.bounds, null,
				mxConstants.OUTLINE_COLOR, mxConstants.OUTLINE_STROKEWIDTH);
			this.selectionBorder.dialect = this.outline.dialect;

			if (this.forceVmlHandles) {
				this.selectionBorder.isHtmlAllowed = function () {
					return false;
				};
			}

			this.selectionBorder.init(this.outline.getView().getOverlayPane());

			// Creates a small blue rectangle for sizing (sizer handle)
			this.sizer = this.createSizer();

			if (this.forceVmlHandles) {
				this.sizer.isHtmlAllowed = function () {
					return false;
				};
			}

			this.sizer.init(this.outline.getView().getOverlayPane());

			if (this.enabled) {
				this.sizer.node.style.cursor = 'nwse-resize';
			}

			mxEvent.addGestureListeners(this.sizer.node, handler);

			this.selectionBorder.node.style.display = (this.showViewport) ? '' : 'none';
			this.sizer.node.style.display = this.selectionBorder.node.style.display;
			this.selectionBorder.node.style.cursor = 'move';

			// Handles event by catching the initial pointer start and then listening to the
			// complete gesture on the event target. This is needed because all the events
			// are routed via the initial element even if that element is removed from the
			// DOM, which happens when we repaint the selection border and zoom handles.
			var handler = mxUtils.bind(this, function (evt) {
				var t = mxEvent.getSource(evt);

				var redirect = mxUtils.bind(this, function (evt) {
					this.outline.fireMouseEvent(mxEvent.MOUSE_MOVE, new mxMouseEvent(evt));
				});

				var redirect2 = mxUtils.bind(this, function (evt) {
					mxEvent.removeGestureListeners(t, null, redirect, redirect2);
					this.outline.fireMouseEvent(mxEvent.MOUSE_UP, new mxMouseEvent(evt));
				});

				mxEvent.addGestureListeners(t, null, redirect, redirect2);
				this.outline.fireMouseEvent(mxEvent.MOUSE_DOWN, new mxMouseEvent(evt));
			});

			mxEvent.addGestureListeners(this.selectionBorder.node, handler);
		}

		// Refreshes the graph in the outline after a refresh of the main graph
		this.refreshHandler = mxUtils.bind(this, function (sender) {
			this.outline.setStylesheet(this.source.getStylesheet());
			this.outline.refresh();
		});
		this.source.addListener(mxEvent.REFRESH, this.refreshHandler);

		this.update(false);
	};

	mxOutline.prototype.update = function (revalidate) {
		if (this.source == null || this.outline == null)
			return;

		if (this.mode == 'preview') {
			if( !this.suspended) {
				this.outline.fit(null, null, 10);
			}
		}
		else {
			var sourceScale = this.source.view.scale;
			var scaledGraphBounds = this.getSourceGraphBounds();
			var unscaledGraphBounds = new mxRectangle(scaledGraphBounds.x / sourceScale + this.source.panDx,
				scaledGraphBounds.y / sourceScale + this.source.panDy, scaledGraphBounds.width / sourceScale,
				scaledGraphBounds.height / sourceScale);

			var unscaledFinderBounds = new mxRectangle(0, 0,
				this.source.container.clientWidth / sourceScale,
				this.source.container.clientHeight / sourceScale);

			var union = unscaledGraphBounds.clone();
			union.add(unscaledFinderBounds);

			// Zooms to the scrollable area if that is bigger than the graph
			var size = this.getSourceContainerSize();
			var completeWidth = Math.max(size.width / sourceScale, union.width);
			var completeHeight = Math.max(size.height / sourceScale, union.height);

			var availableWidth = Math.max(0, this.outline.container.clientWidth - this.border);
			var availableHeight = Math.max(0, this.outline.container.clientHeight - this.border);

			var outlineScale = Math.min(availableWidth / completeWidth, availableHeight / completeHeight);
			var scale = (isNaN(outlineScale)) ? this.minScale : Math.max(this.minScale, outlineScale);

			if (scale > 0) {
				if (this.outline.getView().scale != scale) {
					this.outline.getView().scale = scale;
					revalidate = true;
				}

				var navView = this.outline.getView();

				if (navView.currentRoot != this.source.getView().currentRoot) {
					navView.setCurrentRoot(this.source.getView().currentRoot);
				}

				var t = this.source.view.translate;
				var tx = t.x + this.source.panDx;
				var ty = t.y + this.source.panDy;

				var off = this.getOutlineOffset(scale);

				if (off != null) {
					tx += off.x;
					ty += off.y;
				}

				if (unscaledGraphBounds.x < 0) {
					tx = tx - unscaledGraphBounds.x;
				}
				if (unscaledGraphBounds.y < 0) {
					ty = ty - unscaledGraphBounds.y;
				}

				if (navView.translate.x != tx || navView.translate.y != ty) {
					navView.translate.x = tx;
					navView.translate.y = ty;
					revalidate = true;
				}

				// Prepares local variables for computations
				var t2 = navView.translate;
				scale = this.source.getView().scale;
				var scale2 = scale / navView.scale;
				var scale3 = 1.0 / navView.scale;
				var container = this.source.container;

				// Updates the bounds of the viewrect in the navigation
				this.bounds = new mxRectangle(
					(t2.x - t.x - this.source.panDx) / scale3,
					(t2.y - t.y - this.source.panDy) / scale3,
					(container.clientWidth / scale2),
					(container.clientHeight / scale2));

				// Adds the scrollbar offset to the finder
				this.bounds.x += this.source.container.scrollLeft * navView.scale / scale;
				this.bounds.y += this.source.container.scrollTop * navView.scale / scale;

				var b = this.selectionBorder.bounds;

				if (b.x != this.bounds.x || b.y != this.bounds.y || b.width != this.bounds.width || b.height != this.bounds.height) {
					this.selectionBorder.bounds = this.bounds;
					this.selectionBorder.redraw();
				}

				// Updates the bounds of the zoom handle at the bottom right
				var b = this.sizer.bounds;
				var b2 = new mxRectangle(this.bounds.x + this.bounds.width - b.width / 2,
					this.bounds.y + this.bounds.height - b.height / 2, b.width, b.height);

				if (b.x != b2.x || b.y != b2.y || b.width != b2.width || b.height != b2.height) {
					this.sizer.bounds = b2;

					// Avoids update of visibility in redraw for VML
					if (this.sizer.node.style.visibility != 'hidden') {
						this.sizer.redraw();
					}
				}
			}
		}

		if (revalidate) {
			this.outline.view.revalidate();
		}
	};

	mxGraph.prototype.alignOnTopLeft = function(margin){
		if( margin == null ){
			margin = 50;
		}

		var bounds = this.view.getGraphBounds();
		if(bounds.x != null && bounds.y != null && bounds.width > 0 && bounds.height > 0){
			this.view.setTranslate(margin - bounds.x, margin - bounds.y);
		}
	}

	mxGraph.prototype.center = function(horizontal, vertical, cx, cy, margin)
	{
		horizontal = (horizontal != null) ? horizontal : true;
		vertical = (vertical != null) ? vertical : true;
		cx = (cx != null) ? cx : 0.5;
		cy = (cy != null) ? cy : 0.5;
		margin = margin || 0;

		var hasScrollbars = mxUtils.hasScrollbars(this.container);
		var cw = this.container.clientWidth;
		var ch = this.container.clientHeight;
		var bounds = this.getGraphBounds();

		var t = this.view.translate;
		var s = this.view.scale;

		var dx = (horizontal) ? cw - bounds.width + margin : 0;
		var dy = (vertical) ? ch - bounds.height + margin : 0;

		if (!hasScrollbars)
		{
			this.view.setTranslate((horizontal) ? Math.floor(t.x - bounds.x / s + dx * cx / s) : t.x,
				(vertical) ? Math.floor(t.y - bounds.y / s + dy * cy / s) : t.y);
		}
		else
		{
			bounds.x -= t.x;
			bounds.y -= t.y;

			var sw = this.container.scrollWidth;
			var sh = this.container.scrollHeight;

			if (sw > cw)
			{
				dx = 0;
			}

			if (sh > ch)
			{
				dy = 0;
			}

			this.view.setTranslate(Math.floor(dx / 2 - bounds.x), Math.floor(dy / 2 - bounds.y));
			this.container.scrollLeft = (sw - cw) / 2;
			this.container.scrollTop = (sh - ch) / 2;
		}
	};

	//original method checks if there is a background and takes its size if there is
	mxGraph.prototype.fit = function (border, keepOrigin, margin, enabled, ignoreWidth, ignoreHeight) {
		var bounds = this.view.getGraphBounds();
		if (this.container != null && bounds.width > 0 && bounds.height > 0) {
			border = (border != null) ? border : this.getBorder();
			keepOrigin = (keepOrigin != null) ? keepOrigin : false;
			margin = (margin != null) ? margin : 0;
			enabled = (enabled != null) ? enabled : true;
			ignoreWidth = (ignoreWidth != null) ? ignoreWidth : false;
			ignoreHeight = (ignoreHeight != null) ? ignoreHeight : false;

			// Adds spacing and border from css
			var w1 = this.container.clientWidth - 2*margin;
			var h1 = this.container.clientHeight - 2*margin;
			if(w1 <= 0 || h1 <=0){
				margin = 0;
				w1 = this.container.clientWidth;
				h1 = this.container.clientHeight;
			}

			if (keepOrigin && bounds.x != null && bounds.y != null) {
				bounds = bounds.clone();
				bounds.width += bounds.x;
				bounds.height += bounds.y;
				bounds.x = 0;
				bounds.y = 0;
			}

			// LATER: Use unscaled bounding boxes to fix rounding errors
			var s = this.view.scale;
			var w2 = bounds.width / s;
			var h2 = bounds.height / s;


			var s2 = (((ignoreWidth) ? h1 / h2 : (ignoreHeight) ? w1 / w2 :
				Math.min(w1 / w2, h1 / h2)));

			if (this.minFitScale != null) {
				s2 = Math.max(s2, this.minFitScale);
			}

			if (this.maxFitScale != null) {
				s2 = Math.min(s2, this.maxFitScale);
			}

			if (!enabled) {
				return s2;
			}

			if (keepOrigin) {
				if (this.view.scale != s2) {
					this.view.setScale(s2);
				}
				return;
			}

			if (!mxUtils.hasScrollbars(this.container)) {
				var x0 = (bounds.x != null)
					? Math.floor(this.view.translate.x - bounds.x / s + border / s2 + margin/s2)
					: border;
				var y0 = (bounds.y != null)
					? Math.floor(this.view.translate.y - bounds.y / s + border / s2 + margin/s2)
					: border;

				this.view.scaleAndTranslate(s2, x0 + (w1-bounds.width/s*s2)/2/s2, y0+(h1-bounds.height/s*s2)/2/s2);
			}
			else {
				this.view.setScale(s2);
				var b2 = this.getGraphBounds();

				if (b2.x != null) {
					this.container.scrollLeft = b2.x;
				}

				if (b2.y != null) {
					this.container.scrollTop = b2.y;
				}
			}
		}

		return this.view.scale;
	};

	/**
	 * Registers the given action under the given name.
	 */
	Actions.prototype.addAction = function (key, funct, enabled, iconCls, shortcut, title) {
		if (key.substring(key.length - 3) == '...') {
			key = key.substring(0, key.length - 3);
		}

		title = title || mxResources.get(key);

		return this.put(key, new Action(title, funct, enabled, iconCls, shortcut));
	};

	/**
	 * Sets the link for the given cell.
	 */
	Graph.prototype.setLinkForCell = function (cell, link) {
		if (link.match('^(http|ftp)') == null) {
			link = 'http://' + link;
		}
		this.setAttributeForCell(cell, 'link', link);
	};

	/**
	 * Minimize restore icons are replaced with kendo icons
	 */
	mxWindow.prototype.installMinimizeHandler = function () {
		// this.minimize = document.createElement('img');
		//
		// this.minimize.setAttribute('src', this.minimizeImage);
		// this.minimize.setAttribute('title', 'Minimize');
		// this.minimize.style.cursor = 'pointer';
		// this.minimize.style.marginLeft = '2px';
		// this.minimize.style.display = 'none';
		//

		this.minimize = $('<span class="k-icon k-i-minimize"></span>')[0];
		this.buttons.appendChild(this.minimize);

		var minimized = false;
		var maxDisplay = null;
		var height = null;

		var funct = mxUtils.bind(this, function (evt) {
			this.activate();

			if (!minimized) {
				minimized = true;

				//this.minimize.setAttribute('src', this.normalizeImage);
				//this.minimize.setAttribute('title', 'Normalize');
				$(this.minimize)
					.removeClass('k-i-minimize')
					.addClass('k-i-restore');
				this.contentWrapper.style.display = 'none';
				maxDisplay = this.maximize.style.display;

				this.maximize.style.display = 'none';
				height = this.table.style.height;

				var minSize = this.getMinimumSize();

				if (minSize.height > 0) {
					if (!mxClient.IS_QUIRKS) {
						this.div.style.height = minSize.height + 'px';
					}

					this.table.style.height = minSize.height + 'px';
				}

				if (minSize.width > 0) {
					if (!mxClient.IS_QUIRKS) {
						this.div.style.width = minSize.width + 'px';
					}

					this.table.style.width = minSize.width + 'px';
				}

				if (this.resize != null) {
					this.resize.style.visibility = 'hidden';
				}

				this.fireEvent(new mxEventObject(mxEvent.MINIMIZE, 'event', evt));
			}
			else {
				minimized = false;

				// this.minimize.setAttribute('src', this.minimizeImage);
				// this.minimize.setAttribute('title', 'Minimize');

				$(this.minimize)
					.addClass('k-i-minimize')
					.removeClass('k-i-restore');

				this.contentWrapper.style.display = ''; // default
				this.maximize.style.display = maxDisplay;

				if (!mxClient.IS_QUIRKS) {
					this.div.style.height = height;
				}

				this.table.style.height = height;

				if (this.resize != null) {
					this.resize.style.visibility = '';
				}

				this.fireEvent(new mxEventObject(mxEvent.NORMALIZE, 'event', evt));
			}

			mxEvent.consume(evt);
		});

		mxEvent.addGestureListeners(this.minimize, funct);
	};

	/**
	 * Close icon is replaced with kendo icon
	 */
	mxWindow.prototype.installCloseHandler = function () {
		this.closeImg = $('<span class="k-icon k-i-close"></span>')[0];

		this.buttons.appendChild(this.closeImg);

		mxEvent.addGestureListeners(this.closeImg,
			mxUtils.bind(this, function (evt) {
				this.fireEvent(new mxEventObject(mxEvent.CLOSE, 'event', evt));

				if (this.destroyOnClose) {
					this.destroy();
				}
				else {
					this.setVisible(false);
				}

				mxEvent.consume(evt);
			}));
	};

//overriden to set color of ceeview figures to INVALID if no color set
//but this should be done some other way:(
	var mxShapeDefaultApply = mxShape.prototype.apply;
	mxShape.prototype.apply = function (state) {
		mxShapeDefaultApply.apply(this, arguments);

		if (this.style != null && (this.fill == undefined || this.fill == null) && this.style.shape.indexOf('.ceeview.') != -1) {
			this.fill = CssVariables.severityInvalid;
		}
	};

	var defaultMxTextApply = mxText.prototype.apply;
	mxText.prototype.apply = function (c, x, y, w, h) {
		defaultMxTextApply.apply(this, arguments);
		this.fitText = mxUtils.getValue(this.style, 'fitText', 0);
	};

	var defaultMxTextPaint = mxText.prototype.paint;
	mxText.prototype.paint = function (c, update) {
		var s = this.scale;
		var w = this.bounds.width / s;
		var h = this.bounds.height / s;

		if (this.fitText == 1) {
			this.size = Math.min(w, h);
		}

		defaultMxTextPaint.apply(this, arguments);
	}


	mxSvgCanvas2D.prototype.setFitText = function (fitText) {
		this.state.fitText = fitText;
	}


	var delautMxTextConfigureCanvas = mxText.prototype.configureCanvas;
	mxText.prototype.configureCanvas = function (c, x, y, w, h) {
		delautMxTextConfigureCanvas.apply(this, arguments);
		c.setFitText(this.fitText);
	};


	var defaultMxSvgCanvas2DCreateDiv = mxSvgCanvas2D.prototype.createDiv;
	mxSvgCanvas2D.prototype.createDiv = function (str, align, valign, style, overflow) {
		var div = defaultMxSvgCanvas2DCreateDiv.apply(this, arguments);

		if (this.state.fitText == 1) {
			$(div).css('font-family', 'inherit').css('line-height', '1');
		}

		return div;
	}

//




	mxConstants.STYLE_IMAGE_PADDING = 'imagePadding';
	mxConstants.STYLE_IMAGE_FRAME = 'imageFrame';
	mxConstants.STYLE_IMAGE_CONTENT_TYPE = 'imageContentType';
	mxConstants.STYLE_IMAGE_ICONS_PACK = 'imageIconsPack';
	mxConstants.STYLE_DEFAULT_FILL = 'defaultFillColor';
	mxConstants.STYLE_ICON_COLOR = 'iconColor';
	const customSifts = [
		{name: 'asset-generic', x: 2, y: -2},
		{name: 'asset-linux', x: 2, y: -2}
	];

	mxImageShape.prototype.paintVertexShape = function (c, x, y, w, h) {
		if (this.image != null) {
			var fill = mxUtils.getValue(this.style, mxConstants.STYLE_IMAGE_BACKGROUND, null);
			var defaultFillColor = mxUtils.getValue(this.style, mxConstants.STYLE_DEFAULT_FILL, null);
			if (defaultFillColor != null && fill == null)
				fill = defaultFillColor;

			var stroke = mxUtils.getValue(this.style, mxConstants.STYLE_IMAGE_BORDER, null);
			var rounded = mxUtils.getValue(this.style, mxConstants.STYLE_ROUNDED, 0);
			var frame = mxUtils.getValue(this.style, mxConstants.STYLE_IMAGE_FRAME, 'rect')

			if (fill != null || stroke != null) {
				c.setFillColor(fill);
				c.setStrokeColor(stroke);

				if (frame != 'ellipse') {
					if (rounded == 1)
						c.roundrect(x, y, w, h, 10, 10);
					else
						c.rect(x, y, w, h);
				} else {
					c.ellipse(x, y, w, h);
				}

				c.fillAndStroke();
			}

			var paddingPercent = mxUtils.getValue(this.style, mxConstants.STYLE_IMAGE_PADDING, 0);
			var paddingX = w * paddingPercent / 100;
			var paddingY = h * paddingPercent / 100;

			var imageType = mxUtils.getValue(this.style, mxConstants.STYLE_IMAGE_CONTENT_TYPE, 'image');

			if (imageType == 'image') {
				let imageUrl = this.image;

				let designer = this.designer;
				if( designer == null && this.state){
					designer =  this.state.view.graph.designer;
				}

				if( designer ){
					imageUrl = designer.generateImageUrl(this.image);
				}

				let shiftX = 0;
				let shiftY = 0;
				const customShift = customSifts.find(x => imageUrl.indexOf(x.name) != -1 );
				if(customShift){
					shiftX = customShift.x;
					shiftY = customShift.y;
				}

				c.image(x + paddingX + shiftX, y + paddingY + shiftY, w - paddingX * 2, h - paddingY * 2, imageUrl, this.preserveImageAspect, false, false);

			} else if( imageType == 'icon') {

				var iconsPack = mxUtils.getValue(this.style, mxConstants.STYLE_IMAGE_ICONS_PACK, "material");
				var iconColor = mxUtils.getValue(this.style, mxConstants.STYLE_ICON_COLOR, '#FFFFFF');

				c.setFontSize(Math.min(w, h) * 0.6);
				c.setFontColor(iconColor);

				if (iconsPack == 'material') {
					c.text(x + w / 2, y + h / 2, w, h, '<i class="material-icons">' + this.image + '</i>', mxConstants.ALIGN_CENTER, mxConstants.ALIGN_MIDDLE, 0, 'html', 0, 0, 0);
				} else {
					var left = x + w / 2;

					//glyphicons has diffierent width so here we we have custom positioning for default icon for elements
					if (this.image == 'file')
						left += w * 0.01;

					c.text(left, y + h / 2*1.1 - 2, w, h, '<div class="icon-container"><i class="glyphicons ' + this.image + '"/></div>', mxConstants.ALIGN_CENTER, mxConstants.ALIGN_MIDDLE, 0, 'html', 0, 0, 0);
				}
			}
		}
		else {
			mxRectangleShape.prototype.paintBackground.apply(this, arguments);
		}
	}

	mxLabel.prototype.paintImage = function(c, x, y, w, h)
	{
		if (this.image != null)
		{
			let bounds = this.getImageBounds(x, y, w, h);
			let image = this.image;


			let designer = this.designer;
			if( designer == null && this.state){
				designer =  this.state.view.graph.designer;
			}

			if( designer ){
				image = designer.generateImageUrl(this.image);
			}

			c.image(bounds.x, bounds.y, bounds.width, bounds.height, image, false, false, false);
		}
	};

	mxCell.prototype.setAttribute = function (name, value) {
		var userObject = this.getValue();

		if (!mxUtils.isNode(userObject)) {
			var doc = mxUtils.createXmlDocument();
			var obj = doc.createElement('object');
			obj.setAttribute('label', userObject || '');
			userObject = obj;
			this.setValue(userObject);
		}

		userObject.setAttribute(name, value);
	};

	mxCell.prototype.getPositionIndex = function(){
		return this.parent.children.findIndex( x => x == this);
	}

	mxCell.prototype.getRedirectConfig = function() {
		let redirectConfig = null;

		try{
			redirectConfig = JSON.parse(this.getAttribute('redirectConfig') || null);
		}catch{

		}

		if (redirectConfig == null) { //legacy link property in datasource
			const link = this.readValue("link", false);
			const linkInNewTab = this.readValue('linkInNewTab', false) === "true";

			if (link != null && link != '') {
				redirectConfig = {
					type: RedirectType.Custom,
					link
				}
			}
		}

		if (redirectConfig == null) {
			redirectConfig = defaultRedirectConfig
		}

		return convertLegacyConfig(redirectConfig)
	}

	mxCell.prototype.getDatasource = function () {
		let datasource = JSON.parse(this.getAttribute('datasource') || null);

		if (!datasource) {
			const entityType = this.readValue('entityType', false);
			if (entityType == null)
				return null;

			let accounts = this.getAccounts();

			let {services, serviceElements, serviceQualifiers} = this.getServices();

			let slas = this.readValue("slaId");
			if (!Array.isArray(slas) && slas) {
				slas = [{
					id: slas,
					accountId: accounts[0]
				}]
			}

			let assetGroups = this.readValue("assetGroupId");
			if (!Array.isArray(assetGroups)) {
				assetGroups = [{
					id: assetGroups,
					accountId: accounts[0]
				}];
			}

			let assets = this.readValue("assetId");
			let assetMonitors = this.readValue("assetMonitorId");
			if (!Array.isArray(assets) && assets) {
				assets = [{
					id: assets,
					accountId: accounts[0]
				}];

				if (!Array.isArray(assetMonitors) && assetMonitors) {
					assetMonitors = [{
						id: assetMonitors,
						assetId: assets[0].id,
						accountId: accounts[0]
					}];
				}
			}

			let metricIds = this.readValue("metricsIds");
			let totalMetric = this.readValue("totalMetric");
			let totalMetricType = this.readValue("totalMetricType");
			let displayUnitType = this.readValue('displayUnitType', false);
			let showUnit = this.readValue('showUnit', false) == 'true';
			let customUnit = this.readValue('customUnit', false);
			let displayLabelType = this.readValue("displayLabelType", false)
			let metric = {};

			if (entityType == 'metric') {
				totalMetricType = totalMetricType || TotalMetricType.None;
				displayUnitType = displayUnitType || (showUnit ? DisplayUnitType.MetricUnit : 'NONE');
				customUnit = customUnit || '';
				displayLabelType = displayLabelType || DisplayLabelType.None;

				metric = {
					id: metricIds.length > 0 ? metricIds[0].id : null,
					totalMetricType,
					totalMetricId: totalMetricType == TotalMetricType.Metric ? totalMetric : null,
					totalMetricCustomValue: totalMetricType == TotalMetricType.UserInput ? totalMetric : null,
					customUnit: displayUnitType == DisplayUnitType.CustomUnit ? customUnit : null,
					displayUnitType,
					displayLabelType
				}
			}

			datasource = {
				type: this.readValue('entityType', false),
				accounts: accounts,
				showAsLabel: this.readValue('useDataSourceAsLabel', false) === 'true',
				assetGroups: assetGroups,
				assets: assets,
				monitors: assetMonitors,
				metric,
				slas: slas,
				services: services,
				serviceElements: serviceElements,
				serviceQualifiers: serviceQualifiers,
				assetGroup: {
					showSummary: this.readValue("assetGroupShowSummary", false) === 'true',
				},
				readOnly: this.readValue("readOnlyDataSource", false) == "true",
				showHI: this.readValue('showHealthIndex', false) === 'true',
				decimalsNumber: this.readValue('decimalsNumber', false),
				metricValueAsSeverity: this.readValue('metricValueAsSeverity', false) === 'true',
				metricValueToSeverity: this.readValue("metricValueToSeverity"),
				expanded: this.readValue("expanded", false) === "true",
			}
		}

		if (datasource.type == 'kpi') {
			Object.assign(datasource, datasource.kpi)
			delete datasource.kpi
		}

		if (datasource.type == 'metric') {
			datasource.metric.metricId = datasource.metric.id
			delete datasource.metric.id
			Object.assign(datasource, datasource.metric)
			delete datasource.metric
			datasource.showTrend = datasource.datasourceLink
			delete datasource.datasourceLink
		}

		if (datasource.type == 'cost') {
			Object.assign(datasource, datasource.cost)
			delete datasource.cost
		}

		if (datasource.type == 'assetGroup') {
			datasource.showSummary = datasource.assetGroup?.showSummary ?? false
			delete datasource.assetGroup
		}

		if (datasource.type != 'asset') {
			delete datasource.assets
			delete datasource.monitors
		}

		if (datasource.type != 'service') {
			delete datasource.services
			delete datasource.serviceElements
			delete datasource.serviceQualifiers
		}

		if (datasource.type != 'assetGroup') {
			delete datasource.assetGroups
			delete datasource.assetGroup
			delete datasource.expanded
		}

		if (datasource.type != 'kpi') {
			delete datasource.kpi
		}

		if (datasource.type != 'metric') {
			delete datasource.metric
		}

		if (datasource.type != 'cost') {
			delete datasource.cost
		}

		if (datasource.type != 'sla') {
			delete datasource.slas
		}

		if (['kpi', 'metric', 'cost'].indexOf(datasource.type) == -1) {
			delete datasource.metricValueAsSeverity
			delete datasource.metricValueToSeverity
		}

		if (['metric', 'cost'].indexOf(datasource.type) == -1) {
			delete datasource.decimalsNumber
		}

		if (['asset', 'assetGroup'].indexOf(datasource.type) == -1) {
			delete datasource.showHI
			delete datasource.showReasons
		}

		delete datasource.linkInNewTab
		delete datasource.link
		delete datasource.redirectType

		return datasource
	}

	const defaultMetricValueToSeverity = [{
		operator: "lte",
		threshold: ""
	},{
		operator: "lte",
		threshold: ""
	},{
		operator: "lte",
		threshold: ""
	}];

	mxCell.prototype.initDefaultDatasource = function(datasource){
		datasource = datasource || {};

		let result = {
			...datasource,
			type: datasource.type || 'service',
			accounts: datasource.accounts || [Cookies.CeesoftCurrentAccountId],
			services: datasource.services || [],
			serviceElements: datasource.serviceElements || [],
			serviceQualifiers: datasource.serviceQualifiers || [],
			assets: datasource.assets || [],
			assetGroups: datasource.assetGroups || [],
			slas: datasource.slas || [],
			kpi: datasource.kpi || {
				profileId: null,
				categoryId: null,
			},
			assetGroup: datasource.assetGroup ?? {
				showSummary: true
			},
			metric: datasource.metric ?? {
				totalMetricType: TotalMetricType.None,
				displayUnitType: DisplayUnitType.None,
				customUnit: '',
				displayLabelType: DisplayLabelType.None
			},
			cost: datasource.cost ?? {
				costProfileId: null,
				costItemId: null,
				valueType: 'COST',
				calculationType: 'TOTAL',
				costModelId: null,
				currency: 'NOK',
				showCurrency: 'None',
				timePeriod: {
					period: TimePeriodType.Last30Days
				}
			},
			monitors: datasource.monitors || [],
			showAsLabel: datasource.showAsLabel === undefined ? false : datasource.showAsLabel,
			decimalsNumber: datasource.decimalsNumber === undefined ? '' : datasource.decimalsNumber,
			metricValueAsSeverity: datasource.metricValueAsSeverity === undefined ? false : datasource.metricValueAsSeverity,
			metricValueToSeverity: datasource.metricValueToSeverity?.length > 0
				? datasource.metricValueToSeverity
				: JSON.parse(JSON.stringify(defaultMetricValueToSeverity)),
			expanded: datasource.expanded !== null ? datasource.expanded : false
		}

		if(!result.cost.timePeriod){
			result.cost.timePeriod = {
				period: TimePeriodType.Last30Days
			}
			result.cost.currency = 'NOK';
		}

		if(!result.cost.showCurrency){
			result.cost.showCurrency = 'None';
		}

		return result;
	}

	mxCell.prototype.setDatasource = function(datasource){
		this.setAttribute('datasource', JSON.stringify(datasource));
	}

	mxCell.prototype.removeAttribute = function (name) {
		var userObject = this.getValue();

		if (!mxUtils.isNode(userObject)) {
			return;
		}

		userObject.removeAttribute(name);
	};

	mxCell.prototype.isServiceLink = function () {
		return this.getAttribute('nodeType') == 'link';
	};

	mxCell.prototype.isServiceElement = function () {
		//return this.getDatasource()?.serviceElements?.length == 1;

		return this.getAttribute('nodeType') == 'element';
	};

	mxCell.prototype.isServiceRoot = function () {
		//return this.getDatasource()?.serviceElements?.length == 0 && this.getDatasource()?.services?.length == 1;
		return this.getAttribute('nodeType') == 'root';
	};

	mxCell.prototype.isServiceEntity = function () {
		return this.isServiceElement() || this.isServiceLink() || this.isServiceRoot();
	};

	mxCell.prototype.isDown = function (){
		if(this.designerElement?.getState == null)
			return false;

		const state = this.designerElement.getState();

		return state == 'INACTIVE'
	}

	mxCell.prototype.getBackgroundStyleName = function() {
		if( this.isEdge() ){
			return mxConstants.STYLE_STROKECOLOR;
		}

		return this.style.indexOf('image') == 0 || this.style.indexOf('shape=image') != -1
			? mxConstants.STYLE_IMAGE_BACKGROUND
			: mxConstants.STYLE_FILLCOLOR;
	}

	mxGraphModel.prototype.getServiceRoot = function() {
		for (let i in this.cells) {
			if (!this.cells.hasOwnProperty(i))
				continue;

			if( this.cells[i].isServiceRoot() )
				return this.cells[i];
		}

		return null;
	}

	mxGraphModel.prototype.forEachCell = function(callback) {
		for (let i in this.cells) {
			if (!this.cells.hasOwnProperty(i))
				continue;

			callback(this.cells[i], i);
		}
	}

	const mxGraphModelCloneCellImplOriginal = mxGraphModel.prototype.cloneCellImpl
	mxGraphModel.prototype.cloneCellImpl = function(cell, mapping, includeChildren) {
		if(cell.getAttribute("generatedCell") == "true"){
			return null
		}

		return mxGraphModelCloneCellImplOriginal.apply(this, arguments)
	}


	function clickedOnExpandCollapseIcon(me){
		//this is a dirty hack - when user clicks on an element with expand\collapse button and that element has data source attached
		//we dont need to redirect user to that data source.
		//But handler for clicking on expand\collapse is executed much further from this place where we pass click event to states manager
		//so we are checking if click happend on element with href and it contains name of our images

		let href = me.evt.target.href?.baseVal || me.evt.target.previousSibling?.href?.baseVal; //the last case is for FF, for some reason it has Rect on top of the image

		return href && (href.indexOf('collapsed') != -1 || href.indexOf('expanded') != -1);
	}

	let defaultMxGraphHandlerMouseUp = mxGraphHandler.prototype.mouseUp;
	mxGraphHandler.prototype.mouseUp = function(sender, me) {
		defaultMxGraphHandlerMouseUp.apply(this, arguments);

		if(!clickedOnExpandCollapseIcon(me)) {
			this.graph.fireEvent(new mxEventObject('CELL_CLICKED', 'cell', me.getCell(), 'mouseEvent', me));
		}
	};

	mxPopupMenu.prototype.addHeader = function(parent, header)
	{
		parent = parent || this;

		if (parent.willAddSeparator)
		{
			if (parent.containsItems)
			{
				this.addSeparator(parent, true);
			}

			parent.willAddSeparator = false;
		}

		var tr = document.createElement('tr');
		tr.className = 'mxPopupMenuItem';

		var col1 = document.createElement('td');
		col1.className = 'mxPopupMenuIcon';
		col1.style.padding = '0 0 0 0px';

		tr.appendChild(col1);

		var col2 = document.createElement('td');
		col2.style.paddingLeft = '10px';
		col2.className = 'mxPopupMenuItem';
		col2.setAttribute('colSpan', '2');
		col2.innerText = header;

		tr.appendChild(col2);

		parent.tbody.appendChild(tr);
	};


	mxGraph.prototype.isCellSelectable = function(cell){
		if(!this.cellsSelectable || this.designer.config.disableCellSelection)
			return false;

		var state = this.view.getState(cell);
		var style = (state != null) ? state.style : this.getCellStyle(cell);

		return style["selectable"] != 0;
	};

	let mxGrapIsCellResizableDefault = mxGraph.prototype.isCellResizable;
	mxGraph.prototype.isCellResizable = function(cell){
		if (!mxGrapIsCellResizableDefault.apply(this, arguments) )
			return false;

		return !this.designer.config.chromeless;
	}

	var mxCellEditorApplyDefault = mxCellEditor.prototype.applyValue;
	mxCellEditor.prototype.applyValue = function (state, value) {
		let prevCharacterIsNewLine = false;
		let currentCharacterIsNewLine = false;
		for(let i=0; i<value.length; i++){
			if(value[i] == '\r' || value[i] == '\n'){
				currentCharacterIsNewLine = true;
			}

			if( prevCharacterIsNewLine && !currentCharacterIsNewLine){
				if( value[i] != ' ' ){
					value = value.substr(0, i) + ' ' + value.substr(i);
					i++;
				}
			}

			prevCharacterIsNewLine = currentCharacterIsNewLine;
			currentCharacterIsNewLine = false;
		}
		mxCellEditorApplyDefault.apply(this, [state, value]);
	};

	let OutlineWindow = function (editorUi, x, y, w, h) {
		var graph = editorUi.editor.graph;
		const windowContainer = document.createElement('div');
		windowContainer.style.width = '100%';
		windowContainer.style.height = '100%';

		const graphContainer = document.createElement('div');
		//div.style.position = 'absolute';
		graphContainer.style.width = '100%';
		graphContainer.style.height = '100%';
		graphContainer.style.overflow = 'hidden';
		//graphContainer.style.height = 'calc(100% - 30px)'; //30 - heigh of a kendo window titlebar
		windowContainer.append(graphContainer);
		// div.style.border = '1px solid whiteSmoke';


		//this.window = new mxWindow(lang.PREVIEW, div, x, y, w, h, true, true);

		var outline = editorUi.createOutline();
		outline.suspended = true;

		this.window = $(windowContainer).kendoWindow({
			width: 400,
			height: 320,
			position: {
				top: y,
				left: x
			},

			close: $.proxy(function (e) {
				this.isWindowOpened = false;
				outline.suspended = true;
			}, this),

			activate: $.proxy(function (e) {
				this.isWindowOpened = true;
				outline.suspended = false;
				outline.outline.refresh();
				outline.update();
			}, this),

			resize: function () {
				outline.update(false);
				outline.outline.sizeDidChange();
				showResolution(this.wrapper);
			},
		}).data("kendoWindow");

		function showResolution(wrapper) {
			var windowWidth = Math.round(wrapper.width());
			var windowHeight = Math.round(wrapper.height());
			if (windowWidth && windowHeight) {
				wrapper.find('.k-window-title').html(windowWidth + ' x ' + windowHeight);
			}
		}
		showResolution(this.window.wrapper);

		//var outlineCreateGraph = outline.createGraph;
		outline.createGraph = function (container) {

			let g = new Graph(container, this.source.getModel(), this.graphRenderHint, this.source.getStylesheet());
			g.foldingEnabled = false;
			g.autoScroll = false;
			g.gridEnabled = false;
			g.pageScale = graph.pageScale;
			g.pageFormat = graph.pageFormat;
			g.background = graph.background;
			g.pageVisible = graph.pageVisible;
			g.backgroundImage = graph.backgroundImage;
			g.designer = this.source.designer;

			const current = mxUtils.getCurrentStyle(graph.container);
			graphContainer.style.backgroundColor = current.backgroundColor;

			return g;
		};

		function update() {
			outline.outline.pageScale = graph.pageScale;
			outline.outline.pageFormat = graph.pageFormat;
			outline.outline.pageVisible = graph.pageVisible;
			outline.outline.background = graph.background;
			outline.outline.backgroundImage = graph.backgroundImage;

			var current = mxUtils.getCurrentStyle(graph.container);
			graphContainer.style.backgroundColor = current.backgroundColor;

			if (graph.view.backgroundPageShape != null && outline.outline.view.backgroundPageShape != null) {
				outlin.outline.view.backgroundPageShape.fill = graph.view.backgroundPageShape.fill;
			}

			outline.outline.refresh();
		};

		outline.init(graphContainer);

		this.window.open();

		editorUi.editor.addListener('resetGraphView', update);
		editorUi.addListener('pageFormatChanged', update);
		editorUi.addListener('backgroundColorChanged', update);
		editorUi.addListener('backgroundImageChanged', update);
		editorUi.addListener('pageViewChanged', function () {
			update();
			outline.update(true);
		});

		if (outline.outline.dialect == mxConstants.DIALECT_SVG) {
			var zoomInAction = editorUi.actions.get('zoomIn');
			var zoomOutAction = editorUi.actions.get('zoomOut');

			mxEvent.addMouseWheelListener(function (evt, up) {
				var outlineWheel = false;
				var source = mxEvent.getSource(evt);

				while (source != null) {
					if (source == outline.outline.view.canvas.ownerSVGElement) {
						outlineWheel = true;
						break;
					}

					source = source.parentNode;
				}

				if (outlineWheel) {
					if (up) {
						zoomInAction.funct();
					}
					else {
						zoomOutAction.funct();
					}

					mxEvent.consume(evt);
				}
			});
		}

		this.destroy = function () {
			if (this.window != null) {
				this.window.destroy();
				this.window = null;
			}
		}

		this.toggle = function () {
			if (this.isWindowOpened)
				this.window.close();
			else{
				this.window.open();
			}
		}
	};

	window.OutlineWindow = OutlineWindow;

	//next to methods changed so we have selection border bigger than shape
	mxVertexHandler.prototype.drawPreview = function()
	{
		if (this.preview != null)
		{
			this.preview.bounds = this.bounds;

			if (this.preview.node.parentNode == this.graph.container)
			{
				this.preview.bounds.width = Math.max(0, this.preview.bounds.width - 1);
				this.preview.bounds.height = Math.max(0, this.preview.bounds.height - 1);
			}

			this.preview.rotation = Number(this.state.style[mxConstants.STYLE_ROTATION] || '0');
			this.preview.redraw();
		}

		this.selectionBorder.bounds = new mxRectangle(this.bounds.x - 5, this.bounds.y - 5,
			this.bounds.width + 10, this.bounds.height + 10);
		this.selectionBorder.redraw();

		if (this.parentHighlight != null)
		{
			this.parentHighlight.redraw();
		}
	};

	mxVertexHandler.prototype.redrawHandles = function()
	{
		var tol = this.tolerance;
		this.horizontalOffset = 0;
		this.verticalOffset = 0;

		var s =  new mxRectangle(this.bounds.x - 5, this.bounds.y - 5,
			this.bounds.width + 10, this.bounds.height + 10);

		if (this.sizers != null && this.sizers.length > 0 && this.sizers[0] != null)
		{
			if (this.index == null && this.manageSizers && this.sizers.length >= 8)
			{
				// KNOWN: Tolerance depends on event type (eg. 0 for mouse events)
				var padding = this.getHandlePadding();
				this.horizontalOffset = padding.x;
				this.verticalOffset = padding.y;

				if (this.horizontalOffset != 0 || this.verticalOffset != 0)
				{
					s = new mxRectangle(s.x, s.y, s.width, s.height);

					s.x -= this.horizontalOffset / 2;
					s.width += this.horizontalOffset;
					s.y -= this.verticalOffset / 2;
					s.height += this.verticalOffset;
				}

				if (this.sizers.length >= 8)
				{
					if ((s.width < 2 * this.sizers[0].bounds.width + 2 * tol) ||
						(s.height < 2 * this.sizers[0].bounds.height + 2 * tol))
					{
						this.sizers[0].node.style.display = 'none';
						this.sizers[2].node.style.display = 'none';
						this.sizers[5].node.style.display = 'none';
						this.sizers[7].node.style.display = 'none';
					}
					else
					{
						this.sizers[0].node.style.display = '';
						this.sizers[2].node.style.display = '';
						this.sizers[5].node.style.display = '';
						this.sizers[7].node.style.display = '';
					}
				}
			}

			var r = s.x + s.width;
			var b = s.y + s.height;

			if (this.singleSizer)
			{
				this.moveSizerTo(this.sizers[0], r, b);
			}
			else
			{
				var cx = s.x + s.width / 2;
				var cy = s.y + s.height / 2;

				if (this.sizers.length >= 8)
				{
					var crs = ['nw-resize', 'n-resize', 'ne-resize', 'e-resize', 'se-resize', 's-resize', 'sw-resize', 'w-resize'];

					var alpha = mxUtils.toRadians(this.state.style[mxConstants.STYLE_ROTATION] || '0');
					var cos = Math.cos(alpha);
					var sin = Math.sin(alpha);

					var da = Math.round(alpha * 4 / Math.PI);

					var ct = new mxPoint(s.getCenterX(), s.getCenterY());
					var pt = mxUtils.getRotatedPoint(new mxPoint(s.x, s.y), cos, sin, ct);

					this.moveSizerTo(this.sizers[0], pt.x, pt.y);
					this.sizers[0].setCursor(crs[mxUtils.mod(0 + da, crs.length)]);

					pt.x = cx;
					pt.y = s.y;
					pt = mxUtils.getRotatedPoint(pt, cos, sin, ct);

					this.moveSizerTo(this.sizers[1], pt.x, pt.y);
					this.sizers[1].setCursor(crs[mxUtils.mod(1 + da, crs.length)]);

					pt.x = r;
					pt.y = s.y;
					pt = mxUtils.getRotatedPoint(pt, cos, sin, ct);

					this.moveSizerTo(this.sizers[2], pt.x, pt.y);
					this.sizers[2].setCursor(crs[mxUtils.mod(2 + da, crs.length)]);

					pt.x = s.x;
					pt.y = cy;
					pt = mxUtils.getRotatedPoint(pt, cos, sin, ct);

					this.moveSizerTo(this.sizers[3], pt.x, pt.y);
					this.sizers[3].setCursor(crs[mxUtils.mod(7 + da, crs.length)]);

					pt.x = r;
					pt.y = cy;
					pt = mxUtils.getRotatedPoint(pt, cos, sin, ct);

					this.moveSizerTo(this.sizers[4], pt.x, pt.y);
					this.sizers[4].setCursor(crs[mxUtils.mod(3 + da, crs.length)]);

					pt.x = s.x;
					pt.y = b;
					pt = mxUtils.getRotatedPoint(pt, cos, sin, ct);

					this.moveSizerTo(this.sizers[5], pt.x, pt.y);
					this.sizers[5].setCursor(crs[mxUtils.mod(6 + da, crs.length)]);

					pt.x = cx;
					pt.y = b;
					pt = mxUtils.getRotatedPoint(pt, cos, sin, ct);

					this.moveSizerTo(this.sizers[6], pt.x, pt.y);
					this.sizers[6].setCursor(crs[mxUtils.mod(5 + da, crs.length)]);

					pt.x = r;
					pt.y = b;
					pt = mxUtils.getRotatedPoint(pt, cos, sin, ct);

					this.moveSizerTo(this.sizers[7], pt.x, pt.y);
					this.sizers[7].setCursor(crs[mxUtils.mod(4 + da, crs.length)]);

					this.moveSizerTo(this.sizers[8], cx + this.state.absoluteOffset.x, cy + this.state.absoluteOffset.y);
				}
				else if (this.state.width >= 2 && this.state.height >= 2)
				{
					this.moveSizerTo(this.sizers[0], cx + this.state.absoluteOffset.x, cy + this.state.absoluteOffset.y);
				}
				else
				{
					this.moveSizerTo(this.sizers[0], this.state.x, this.state.y);
				}
			}
		}

		if (this.rotationShape != null)
		{
			var alpha = mxUtils.toRadians((this.currentAlpha != null) ? this.currentAlpha : this.state.style[mxConstants.STYLE_ROTATION] || '0');
			var cos = Math.cos(alpha);
			var sin = Math.sin(alpha);

			var ct = new mxPoint(this.state.getCenterX(), this.state.getCenterY());
			var pt = mxUtils.getRotatedPoint(this.getRotationHandlePosition(), cos, sin, ct);

			if (this.rotationShape.node != null)
			{
				this.moveSizerTo(this.rotationShape, pt.x, pt.y);

				// Hides rotation handle during text editing
				this.rotationShape.node.style.visibility = (this.state.view.graph.isEditing()) ? 'hidden' : '';
			}
		}

		if (this.selectionBorder != null)
		{
			this.selectionBorder.rotation = Number(this.state.style[mxConstants.STYLE_ROTATION] || '0');
		}

		if (this.edgeHandlers != null)
		{
			for (var i = 0; i < this.edgeHandlers.length; i++)
			{
				this.edgeHandlers[i].redraw();
			}
		}

		if (this.customHandles != null)
		{
			for (var i = 0; i < this.customHandles.length; i++)
			{
				var temp = this.customHandles[i].shape.node.style.display;
				this.customHandles[i].redraw();
				this.customHandles[i].shape.node.style.display = temp;

				// Hides custom handles during text editing
				this.customHandles[i].shape.node.style.visibility = (this.graph.isEditing()) ? 'hidden' : '';
			}
		}

		this.updateParentHighlight();
	};

	//here we pass graph to background mxImageShape so we can access it later to generate image paths
	mxGraphView.prototype.validateBackgroundImage = function()
	{
		var bg = this.graph.getBackgroundImage();

		if (bg != null)
		{
			if (this.backgroundImage == null || this.backgroundImage.image != bg.src)
			{
				if (this.backgroundImage != null)
				{
					this.backgroundImage.destroy();
				}

				var bounds = new mxRectangle(0, 0, 1, 1);

				this.backgroundImage = new mxImageShape(bounds, bg.src);
				this.backgroundImage.dialect = this.graph.dialect;
				this.backgroundImage.init(this.backgroundPane);
				this.backgroundImage.designer = this.graph.designer;
				this.backgroundImage.redraw();

				// Workaround for ignored event on background in IE8 standards mode
				if (document.documentMode == 8 && !mxClient.IS_EM)
				{
					mxEvent.addGestureListeners(this.backgroundImage.node,
						mxUtils.bind(this, function(evt)
						{
							this.graph.fireMouseEvent(mxEvent.MOUSE_DOWN, new mxMouseEvent(evt));
						}),
						mxUtils.bind(this, function(evt)
						{
							this.graph.fireMouseEvent(mxEvent.MOUSE_MOVE, new mxMouseEvent(evt));
						}),
						mxUtils.bind(this, function(evt)
						{
							this.graph.fireMouseEvent(mxEvent.MOUSE_UP, new mxMouseEvent(evt));
						})
					);
				}
			}

			this.redrawBackgroundImage(this.backgroundImage, bg);
		}
		else if (this.backgroundImage != null)
		{
			this.backgroundImage.destroy();
			this.backgroundImage = null;
		}
	};

	let mxRectangleAddOriginal = mxRectangle.prototype.add;
	mxRectangle.prototype.add = function(other){
		if( Number.isNaN(other.height) || Number.isNaN(other.width)
			|| Number.isNaN(other.x) || Number.isNaN(other.y) )
			return;

		mxRectangleAddOriginal.apply(this, arguments);
	}

	let mxDragSourceDragOverOriginal = mxDragSource.prototype.dragOver;
	mxDragSource.prototype.dragOver = function(graph, evt){
		mxDragSourceDragOverOriginal.apply(this, arguments);
		if( this.previewElement != null ){
			this.previewElement.style.left = parseInt(this.previewElement.style.left, 10)
				+ this.currentGraph.container.offsetLeft  + 'px';
			this.previewElement.style.top = parseInt(this.previewElement.style.top, 10)
				+ this.currentGraph.container.offsetTop + 'px';
		}
	}

	mxGraph.prototype.insertEntity = function(entry, geometry){
		const dataSource = entry.dataSource;

		const parent = this.getDefaultParent();

		let styles = Sidebar.prototype.ceeviewStyles[dataSource.type];
		if(entry.additionalStyles){
			styles += entry.additionalStyles;
		}

		let cell = this.insertVertex(parent, null, null,
			geometry.x, geometry.y, geometry.width, geometry.height, styles);

		this.designer.store.dataSourcesManager.addDataSource(dataSource, cell)

		return cell
	}

	mxCell.prototype.readValue = function (attribute, json = true) {
		const value = this.getValueAsXml()
			.getAttribute(attribute);

		if(json){
			return readJsonValue(value)
		}

		return value;
	}

	mxCell.prototype.getServices = function(){
		let services = this.readValue('serviceId');
		let serviceElements = this.readValue('serviceElementId')
		let serviceQualifiers = this.readValue("serviceQualifiers");

		if(!Array.isArray(services) && services){
			let accounts = this.getAccounts();
			services = [{
				id: services,
				accountId: accounts[0]
			}];

			if(serviceElements){
				serviceElements = [{
					id: serviceElements,
					serviceId: services[0].id,
					accountId: accounts[0]
				}];
			}
		}
		return {
			services, serviceElements, serviceQualifiers
		}
	}

	mxCell.prototype.getAccounts = function(){
		let accounts = this.readValue("accountId");
		if(!Array.isArray(accounts) && accounts){
			accounts = [accounts];
		}

		if(accounts.length == 0){
			accounts = [Cookies.CeesoftCurrentAccountId];
		}

		return accounts;
	}

	mxCell.prototype.getValueAsXml = function () {
		var value = this.getValue();

		// Converts the value to an XML node
		if (!mxUtils.isNode(value)) {
			var doc = mxUtils.createXmlDocument();
			var obj = doc.createElement('object');
			obj.setAttribute('label', value || '');
			value = obj;
		}

		return value.cloneNode(true);
	}

	mxUtils.createUserObject = function(properties){
		let doc = mxUtils.createXmlDocument();
		let object = doc.createElement('object');

		for(const [property, value] of Object.entries(properties)){
			object.setAttribute(property, property == 'datasource' ? JSON.stringify(value) : value);
		}

		return object;
	}

	mxUtils.getReadonlyStyles = function(){
		return "resizable=0;movable=0;deletable=0;rotatable=0;editable=0;";
	}

	mxUtils.placeElementsInGrid = function(items, firstItemGeometry, itemsPerRow, xMargin, yMargin, callback) {
		let nextItemGeometry = firstItemGeometry;
		let elementsAdded = 0;

		for (const item of items) {
			callback(item, nextItemGeometry)

			elementsAdded++;

			if ((elementsAdded) % itemsPerRow == 0) {
				nextItemGeometry = new mxPoint(firstItemGeometry.x, nextItemGeometry.y + firstItemGeometry.height + yMargin);
			} else {
				nextItemGeometry = new mxPoint(nextItemGeometry.x + firstItemGeometry.width + xMargin, nextItemGeometry.y);
			}

			nextItemGeometry.width = firstItemGeometry.width;
			nextItemGeometry.height = firstItemGeometry.height;
		}
	}

	//this is a copy-paste of original method with the only change
	//root.style.minHeight = Math.max(1, height + 10) + 'px'; - so the bottom selection of a cell is visible when cell is right at the bottom
	mxGraph.prototype.sizeDidChange = function()
	{
		var bounds = this.getGraphBounds();

		if (this.container != null)
		{
			var border = this.getBorder();

			var width = Math.max(0, bounds.x + bounds.width + 2 * border * this.view.scale);
			var height = Math.max(0, bounds.y + bounds.height + 2 * border * this.view.scale);

			if (this.minimumContainerSize != null)
			{
				width = Math.max(width, this.minimumContainerSize.width);
				height = Math.max(height, this.minimumContainerSize.height);
			}

			if (this.resizeContainer)
			{
				this.doResizeContainer(width, height);
			}

			if (this.preferPageSize || (!mxClient.IS_IE && this.pageVisible))
			{
				var size = this.getPreferredPageSize(bounds, Math.max(1, width), Math.max(1, height));

				if (size != null)
				{
					width = size.width * this.view.scale;
					height = size.height * this.view.scale;
				}
			}

			if (this.minimumGraphSize != null)
			{
				width = Math.max(width, this.minimumGraphSize.width * this.view.scale);
				height = Math.max(height, this.minimumGraphSize.height * this.view.scale);
			}

			width = Math.ceil(width);
			height = Math.ceil(height);

			if (this.dialect == mxConstants.DIALECT_SVG)
			{
				var root = this.view.getDrawPane().ownerSVGElement;

				if(this.config?.chromeless != true){
					height += 10;
				}

				root.style.minWidth = Math.max(1, width) + 'px';
				root.style.minHeight = Math.max(1, height) + 'px';
				root.style.width = '100%';
				root.style.height = '100%';
			}
			else
			{
				if (mxClient.IS_QUIRKS)
				{
					// Quirks mode does not support minWidth/-Height
					this.view.updateHtmlCanvasSize(Math.max(1, width), Math.max(1, height));
				}
				else
				{
					this.view.canvas.style.minWidth = Math.max(1, width) + 'px';
					this.view.canvas.style.minHeight = Math.max(1, height) + 'px';
				}
			}

			this.updatePageBreaks(this.pageBreaksVisible, width, height);
		}

		this.fireEvent(new mxEventObject(mxEvent.SIZE, 'bounds', bounds));
	};

	//copy-paste from mxClient.js because of undedined 'clip' variable
	//clip = this.createElement('clipPath');
	mxSvgCanvas2D.prototype.createClip = function(x, y, w, h)
	{
		x = Math.round(x);
		y = Math.round(y);
		w = Math.round(w);
		h = Math.round(h);

		var id = 'mx-clip-' + x + '-' + y + '-' + w + '-' + h;

		var counter = 0;
		var tmp = id + '-' + counter;

		// Resolves ID conflicts
		while (document.getElementById(tmp) != null)
		{
			tmp = id + '-' + (++counter);
		}

		let clip = this.createElement('clipPath');
		clip.setAttribute('id', tmp);

		var rect = this.createElement('rect');
		rect.setAttribute('x', x);
		rect.setAttribute('y', y);
		rect.setAttribute('width', w);
		rect.setAttribute('height', h);

		clip.appendChild(rect);

		return clip;
	}

	//copy-paste of original method, added 'labelClicked' property to the event
	mxCellRenderer.prototype.createLabel = function(state, value)
	{
		var graph = state.view.graph;
		var isEdge = graph.getModel().isEdge(state.cell);

		if (state.style[mxConstants.STYLE_FONTSIZE] > 0 || state.style[mxConstants.STYLE_FONTSIZE] == null)
		{
			// Avoids using DOM node for empty labels
			var isForceHtml = (graph.isHtmlLabel(state.cell) || (value != null && mxUtils.isNode(value)));

			state.text = new this.defaultTextShape(value, new mxRectangle(),
				(state.style[mxConstants.STYLE_ALIGN] || mxConstants.ALIGN_CENTER),
				graph.getVerticalAlign(state),
				state.style[mxConstants.STYLE_FONTCOLOR],
				state.style[mxConstants.STYLE_FONTFAMILY],
				state.style[mxConstants.STYLE_FONTSIZE],
				state.style[mxConstants.STYLE_FONTSTYLE],
				state.style[mxConstants.STYLE_SPACING],
				state.style[mxConstants.STYLE_SPACING_TOP],
				state.style[mxConstants.STYLE_SPACING_RIGHT],
				state.style[mxConstants.STYLE_SPACING_BOTTOM],
				state.style[mxConstants.STYLE_SPACING_LEFT],
				state.style[mxConstants.STYLE_HORIZONTAL],
				state.style[mxConstants.STYLE_LABEL_BACKGROUNDCOLOR],
				state.style[mxConstants.STYLE_LABEL_BORDERCOLOR],
				graph.isWrapping(state.cell) && graph.isHtmlLabel(state.cell),
				graph.isLabelClipped(state.cell),
				state.style[mxConstants.STYLE_OVERFLOW],
				state.style[mxConstants.STYLE_LABEL_PADDING],
				mxUtils.getValue(state.style, mxConstants.STYLE_TEXT_DIRECTION, mxConstants.DEFAULT_TEXT_DIRECTION));
			state.text.opacity = mxUtils.getValue(state.style, mxConstants.STYLE_TEXT_OPACITY, 100);
			state.text.dialect = (isForceHtml) ? mxConstants.DIALECT_STRICTHTML : state.view.graph.dialect;
			state.text.style = state.style;
			state.text.state = state;
			this.initializeLabel(state, state.text);

			// Workaround for touch devices routing all events for a mouse gesture
			// (down, move, up) via the initial DOM node. IE additionally redirects
			// the event via the initial DOM node but the event source is the node
			// under the mouse, so we need to check if this is the case and force
			// getCellAt for the subsequent mouseMoves and the final mouseUp.
			var forceGetCell = false;

			var getState = function(evt)
			{
				var result = state;

				if (mxClient.IS_TOUCH || forceGetCell)
				{
					var x = mxEvent.getClientX(evt);
					var y = mxEvent.getClientY(evt);

					// Dispatches the drop event to the graph which
					// consumes and executes the source function
					var pt = mxUtils.convertPoint(graph.container, x, y);
					result = graph.view.getState(graph.getCellAt(pt.x, pt.y));
				}

				return result;
			};

			// TODO: Add handling for special touch device gestures
			mxEvent.addGestureListeners(state.text.node,
				mxUtils.bind(this, function(evt)
				{
					if (this.isLabelEvent(state, evt))
					{
						const me = new mxMouseEvent(evt, state)
						me.labelClicked = true;

						graph.fireMouseEvent(mxEvent.MOUSE_DOWN, me);
						forceGetCell = graph.dialect != mxConstants.DIALECT_SVG &&
							mxEvent.getSource(evt).nodeName == 'IMG';
					}
				}),
				mxUtils.bind(this, function(evt)
				{
					if (this.isLabelEvent(state, evt))
					{
						const me = new mxMouseEvent(evt, getState(evt))
						me.labelClicked = true;

						graph.fireMouseEvent(mxEvent.MOUSE_MOVE, me);
					}
				}),
				mxUtils.bind(this, function(evt)
				{
					if (this.isLabelEvent(state, evt))
					{
						const me = new mxMouseEvent(evt, getState(evt))
						me.labelClicked = true;

						graph.fireMouseEvent(mxEvent.MOUSE_UP, me);
						forceGetCell = false;
					}
				})
			);

			// Uses double click timeout in mxGraph for quirks mode
			if (graph.nativeDblClickEnabled)
			{
				mxEvent.addListener(state.text.node, 'dblclick',
					mxUtils.bind(this, function(evt)
					{
						if (this.isLabelEvent(state, evt))
						{
							graph.dblClick(evt, state.cell);
							mxEvent.consume(evt);
						}
					})
				);
			}
		}
	};

	mxGeometry.prototype.copy = function(x, y, width, height){
		return new mxGeometry(x ?? this.x, y ?? this.y, width ?? this.width, height ?? this.height)
	}

	mxGeometry.prototype.isInside = function(geometry){
		return this.x <= geometry.x && geometry.x <= this.x + this.width &&
			this.y <= geometry.y && geometry.y <= this.y + this.height
	}

	mxGraphHandler.prototype.mouseDown = function(sender, me)
	{
		let gridHeader = me.evt.srcElement.closest('div.k-grid-header')
		if(!gridHeader){
			gridHeader = me.evt.srcElement.closest('.ceeview-grid-header')
		}

		if(gridHeader != null) {
			//me.consume();
			return;
		}

		const isResizableHandler = me.evt.srcElement.classList.contains('react-resizable-handle');
		if(isResizableHandler) {
			return;
		}

		const googleMap = me.evt.srcElement.closest('.ol-viewport');
		if(googleMap != null) {
			return;
		}

		//default handler of mouseDown event does not work if graph is disabled
		//but we need a possibility to select nodes even if graph in read-only mode
		//so instead we 'enable' graph for this call and disable Move action only
		var isEnabled = this.graph.isEnabled();
		if ( this.graph.designer && this.graph.designer.config.allowSelectionInReadOnlyMode){
			this.graph.setEnabled(true);
		}


		//this section is copypaste from the default implementation with the last line commented out
		if (!me.isConsumed() && this.isEnabled() && this.graph.isEnabled() &&
			me.getState() != null && !mxEvent.isMultiTouchEvent(me.getEvent()))
		{
			var cell = this.getInitialCellForEvent(me);
			this.delayedSelection = this.isDelayedSelection(cell, me);
			this.cell = null;

			if (this.isSelectEnabled() && !this.delayedSelection)
			{
				this.graph.selectCellForEvent(cell, me.getEvent());
			}

			if (this.isMoveEnabled())
			{
				var model = this.graph.model;
				var geo = model.getGeometry(cell);

				if (this.graph.isCellMovable(cell) && ((!model.isEdge(cell) || this.graph.getSelectionCount() > 1 ||
						(geo.points != null && geo.points.length > 0) || model.getTerminal(cell, true) == null ||
						model.getTerminal(cell, false) == null) || this.graph.allowDanglingEdges ||
					(this.graph.isCloneEvent(me.getEvent()) && this.graph.isCellsCloneable())))
				{
					this.start(cell, me.getX(), me.getY());
				}
				else if (this.delayedSelection)
				{
					this.cell = cell;
				}

				this.cellWasClicked = true;
				//down\up events are not propagated to the widgets because of this
				//this.consumeMouseEvent(mxEvent.MOUSE_DOWN, me);
			}
		}

		//end of the original section

		this.graph.setEnabled(isEnabled);
	};
})();
