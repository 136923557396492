import Settings from 'settings';
import Cookies from 'core/cookies';

export class CoommonWidgetsApi {
	static async fetchSelectedMetrics(accountId, metricIds){
        const url = `${Settings.serverPath}accounts/${accountId}/metrics/registeredMetrics/search?includeSubaccounts=true`;

        const options = {
            method: 'POST',
            body: JSON.stringify({ skip: 0, take: 1000, filter: {
                "logic": "and",
                "filters": [{
                    "logic": "or",
                    "filters": metricIds.map((id) => ({
                        "operator": "eq",
                        "value": id,
                        "field": "metricId"
                    }))
                }]
            }}),
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                "Auth-Token": Cookies.sessionId
            },
            throwOnError: false,
        };
        const response = await fetch(url, options);
        return await response.json();
	}
}
