import {observer} from "mobx-react";
import {Section} from "controls/react/layout";
import {AntCheckbox} from "controls/react/ant/antCheckbox";
import {linkModel} from "framework/mobx-integration";
import React from "react";
import {Link} from "core/react/links";

const i18n = require('core/localization').translator({
	'Grid toolbar settings': {},
	'Refresh Timer': {},
	'Menu': {},
	'Time selector': {},
	'View management': {}
});

export type GridToolbarVisibilityFlags = {
	showMenu?: boolean
	showRefreshTimer?: boolean
	showTimeSettings?: boolean
	showFilters?: boolean
	showViewSelector?: boolean
}

export type GridToolbarVisibilityValueLinkProps = {
	flags: Link<GridToolbarVisibilityFlags>
	enabledFlags?: (keyof GridToolbarVisibilityFlags)[]
}

export const GridToolbarVisibilityValueLink = observer ((props: GridToolbarVisibilityValueLinkProps) => {
	let isFlagEnabled = React.useCallback((flag: keyof GridToolbarVisibilityFlags) => {
		return !props.enabledFlags || props.enabledFlags.indexOf(flag) != -1
	}, [props.enabledFlags])

	return <Section appearance={"frame"} title={i18n('Grid toolbar settings')} childrenPadding={true} direction={"row"}>
		{isFlagEnabled("showMenu") && <AntCheckbox {...props.flags.get('showMenu').props}>{i18n('Menu')}</AntCheckbox>}
		{isFlagEnabled("showRefreshTimer") && <AntCheckbox {...props.flags.get('showRefreshTimer').props}>{i18n('Refresh Timer')}</AntCheckbox>}
		{isFlagEnabled("showTimeSettings") && <AntCheckbox {...props.flags.get('showTimeSettings').props}>{i18n('Time selector')}</AntCheckbox>}
		{isFlagEnabled("showFilters") && <AntCheckbox {...props.flags.get('showFilters').props}>{i18n('Filters')}</AntCheckbox>}
		{isFlagEnabled("showViewSelector") && <AntCheckbox {...props.flags.get('showViewSelector').props}>{i18n('View management')}</AntCheckbox>}
	</Section>
})


export type GridToolbarVisibilityProps = {
	flags: GridToolbarVisibilityFlags
	enabledFlags?: (keyof GridToolbarVisibilityFlags)[]
}

export const GridToolbarVisibility = observer ((props: GridToolbarVisibilityProps) =>{
	let isFlagEnabled = React.useCallback((flag: keyof GridToolbarVisibilityFlags) => {
		return !props.enabledFlags || props.enabledFlags.indexOf(flag) != -1
	}, [props.enabledFlags])

	return <Section appearance={"frame"} title={i18n('Grid toolbar settings')} childrenPadding={true} direction={"row"}>
		{isFlagEnabled("showMenu") && <AntCheckbox {...linkModel(props.flags, "showMenu")}>{i18n('Menu')}</AntCheckbox>}
		{isFlagEnabled("showRefreshTimer") && <AntCheckbox {...linkModel(props.flags, "showRefreshTimer")}>{i18n('Refresh Timer')}</AntCheckbox>}
		{isFlagEnabled("showTimeSettings") && <AntCheckbox {...linkModel(props.flags, "showTimeSettings")}>{i18n('Time selector')}</AntCheckbox>}
		{isFlagEnabled("showFilters") && <AntCheckbox {...linkModel(props.flags, "showFilters")}>{i18n('Filters')}</AntCheckbox>}
		{isFlagEnabled("showViewSelector") && <AntCheckbox {...linkModel(props.flags, "showViewSelector")}>{i18n('View management')}</AntCheckbox>}
	</Section>
})

export function copyVisibilityFlags(source: GridToolbarVisibilityFlags, target: GridToolbarVisibilityFlags){
	let keys : (keyof GridToolbarVisibilityFlags)[] = ['showMenu', 'showFilters', 'showTimeSettings', 'showViewSelector', 'showRefreshTimer']
	for (const key of keys) {
		if(source[key] !== undefined && target[key] === undefined){
			target[key] = source[key]
			source[key] = undefined
		}
	}
}

export function anyFlagSet(flags: GridToolbarVisibilityFlags){
	for(let key of Object.keys(flags)){
		if(flags[key as keyof GridToolbarVisibilityFlags] === true){
			return true
		}
	}

	return false
}
