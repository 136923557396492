import React from 'react'
import {observer} from "mobx-react"
import {deserialize, serialize} from "serializr"

import {ModuleProps} from "framework/modules"
import {Grid} from 'controls/grid/grid'
import {DefaultWidgetWrapper} from "controls/designer/features/widgets/defaultWidgetWrapper"
import {WidgetProps} from "controls/designer/features/widgets/widgetProps"
import {GridState} from "controls/grid/gridState";
import {EventsSummaryEntry} from "areas/summary/events/eventsSummaryEntry";
import {convertLegacyState, LegacyGridWidgetPersistedState} from "controls/grid/legacyGridState";
import {apiFetch} from "framework/api";
import {GridStore} from "controls/grid/gridStore";
import {ExternalStateProvider} from "controls/grid/stateProviders";
import {RemoteDataProvider} from "controls/grid/remoteDataProvider";
import {GridSelectionType} from "controls/grid/gridConfiguration";
import {ServicesListStore} from "areas/services/services-list/servicesListStore";
import {ServicesListEntry} from "areas/services/services-list/servicesListEntry";
import {WidgetConfig, WidgetConfigurationProps} from "controls/designer/features/widgets/widgetConfig";
import {sharedLocalization} from "controls/designer/features/widgets/localization";
import {ApplicationState} from "framework/applicationState";
import {Section, Toolbar} from "controls/react/layout";
import {FormEntry} from "controls/react/form";
import {AntInput} from "controls/react/ant/antInput";
import AccountDropDown from "controls/react/dropdowns/accountDropDown";
import {AntCheckbox} from "controls/react/ant/antCheckbox";
import {TagsSelect} from "controls/react/tagsSelect";
import {RefreshTimer} from "controls/grid/refreshTimer"
import {DataSizeIndicator} from "controls/grid/dataSizeIndicator";
import {GridSearchInput} from "controls/grid/gridSearchInput";
import {ViewSelector} from "controls/grid/viewManager/viewSelector";
import {getServicesListFilters} from "areas/services/services-list/api";
import {CeeviewNavigator} from "tools/ceeviewNavigator";
import {StylesFromMxGraphCellPlugin} from "controls/grid/plugins/stylesFromMxGraphCellPlugin";
import {
	anyFlagSet,
	copyVisibilityFlags,
	GridToolbarVisibilityFlags,
	GridToolbarVisibilityValueLink
} from "areas/service-boards/widgets/grids/gridToolbarVisibility";
import {EventsSummaryGridWidgetConfig} from "areas/service-boards/widgets/grids/eventsSummary";

const i = require('core/localization').translator(sharedLocalization);

export function getWidgetDescription(){
	return {
		form: ServicesGridWidgetConfiguration,
		defaultConfig: {
			type: 'service-grid',
			title: i('Service'),
			configuration: {
				accountId: ApplicationState.accountId
			}
		},
		minWidth: 800,
		widget: ServicesGridWidget,
		fullTitle: i('Service') + '/' + i('Grid'),
		preProcessConfig: (config: ServicesGridWidgetConfig) => {

			if (config.gridToolbarVisibility == null) {
				config.gridToolbarVisibility = {}
			}
			//@ts-ignore
			//changed in 2.18, need to copy legacy flags to a new set
			copyVisibilityFlags(config, config.gridToolbarVisibility)

			return config
		},
	}
}


type ServicesGridWidgetProps = ModuleProps

export type ServicesGridPersistedState = {
	grid: GridState<EventsSummaryEntry>
} | LegacyGridWidgetPersistedState

export interface ServicesGridWidgetConfig extends WidgetConfig {
	accountId: string
	includeSubaccounts: boolean
	tags: string[]
	showUntagged: boolean

	showRefreshTimer: boolean
	showFilters: boolean
	columns: Record<string, {hidden: boolean}>
	gridToolbarVisibility: GridToolbarVisibilityFlags
}

export const ServicesGridWidgetConfiguration = observer((props:WidgetConfigurationProps<ServicesGridWidgetConfig>) => {
	const {configLink} = props;

	return <Section appearance={"none"}
	                contentPadding={false}
	                childrenPadding={true}>
		<Section appearance={"frame"} title={i('Configuration')} childrenPadding={true}>
			<FormEntry label={i('Title')}>
				<AntInput {...configLink.get('title').props}/>
			</FormEntry>
			<FormEntry label={i('Account')}>
				<AccountDropDown {...configLink.get('accountId').props}/>
			</FormEntry>
			<FormEntry>
				<AntCheckbox valueLink={configLink.get('includeSubaccounts')}>{i('Include subaccounts')}</AntCheckbox>
			</FormEntry>
			{props.children}
		</Section>
		<Section appearance={'frame'} title={i('Tags')} childrenPadding={true}>
			<FormEntry label={i('Tags')} vertical={true} width={'fit'}>
				<TagsSelect accountId={configLink.value.accountId}
				            {...configLink.get('tags').props} />
			</FormEntry>
			<AntCheckbox valueLink={configLink.get('showUntagged')}>{i('Show Untagged')}</AntCheckbox>
		</Section>
		<GridToolbarVisibilityValueLink flags={configLink.get('gridToolbarVisibility')}
		                                enabledFlags={["showViewSelector", "showFilters", "showTimeSettings", "showRefreshTimer"]}/>
	</Section>
});



export const ServicesGridWidget = observer(
	class InnerWidget extends React.PureComponent<WidgetProps<ServicesGridWidgetConfig, ServicesGridPersistedState>, ServicesGridWidgetProps> {
		store: ServicesGridWidgetStore;

		constructor(props: WidgetProps<ServicesGridWidgetConfig, ServicesGridPersistedState>) {
			super(props)

			this.store = new ServicesGridWidgetStore(props);
		}

		render(){
			if(!this.store.gridStore?.selfInitialized)
				return null

			const editMode = !this.props.dashboardSettings.readOnly
			const flags = this.props.config.gridToolbarVisibility

			return <DefaultWidgetWrapper {...this.props}>
				<Section appearance="none" height={"fit"}>
					{(anyFlagSet(flags) || editMode) &&
						<Toolbar>
							{(flags.showRefreshTimer || editMode)
								&& <RefreshTimer store={this.store.gridStore}/>}

							{(flags.showFilters || editMode)
								&& <DataSizeIndicator store={this.store.gridStore}/>}

							{(flags.showFilters || editMode)
								&& <GridSearchInput store={this.store.gridStore}/>}

							{(flags.showViewSelector || editMode)
								&& <ViewSelector store={this.store.gridStore} createViewDisabled={!editMode} editViewDisabled={!editMode}/>}
						</Toolbar>
					}
					<Grid store={this.store.gridStore}/>
				</Section>
				</DefaultWidgetWrapper>
		}

		componentWillUnmount() {
			this.store?.destroy()
		}

		getStateForSaving() {
			return {
				grid: serialize(this.store.gridStore.state)
			};
		}
	}
)

type ServicesGridWidgetStoreProps = {
	config: ServicesGridWidgetConfig,
	persistedState: ServicesGridPersistedState,
	navigator: CeeviewNavigator
}

export class ServicesGridWidgetStore extends ServicesListStore{
	props: WidgetProps<ServicesGridWidgetConfig, ServicesGridPersistedState>

	constructor(props: WidgetProps<ServicesGridWidgetConfig, ServicesGridPersistedState>) {
		super();

		this.props = props

		this.init()
	}

	async init(){
		const filtersResult = await apiFetch(getServicesListFilters())
		if(!filtersResult.success){
			return
		}
		const filtersConfiguration = filtersResult.data

		let state = null
		if('grid' in this.props.persistedState){
			state = deserialize<GridState<ServicesListEntry>>(GridState, this.props.persistedState.grid)
		}else if( this.props.persistedState.columns){
			state = convertLegacyState(
				this.getColumns(this.props.navigator),
				this.props.persistedState,
				filtersConfiguration,
				{
					serviceIndicator: {
						name: 'serviceState',
						filter: (value: number) =>  {
							switch (value){
								case 1: return 'DOWN'
								case 2: return 'WARNING'
								case 3: return 'UP'
								case 4: return 'UNAVAILABLE'
								case 5: return 'AGENT_DOWN'
								case 6: return 'AGENT_MAINTENANCE'
								case 7: return 'ASSET_MAINTENANCE'
								case 8: return 'QUALIFIER_ERROR'
								default: return 'DOWN'
							}
						}
					},
					accountName: 'accountId',
					srvHealthIndex: 'serviceHealth',
					srvSlaIndex: 'slaHealth',
					Operational: 'operatingState'
				})
		}

		this.gridStore = new GridStore<ServicesListEntry>({
			columns: this.getColumns(this.props.navigator),
			stateProviders: [
				new ExternalStateProvider(state),
			],
			dataProvider: new RemoteDataProvider({
				dataSource: this.getDefaultDatasource(this.props.config),
				filtersSource: getServicesListFilters()
			}),
			plugins: [
				this.getAutoReloadPlugin(),
				new StylesFromMxGraphCellPlugin(this.props.designer.store, this.props.cell)
			],
			selection: GridSelectionType.Many
		})
	}
}
