import React from "react";

import {Api} from "tools/api";
import Settings from "settings";
import Cookies from "core/cookies";
import {ApiRequest} from "framework/api";

export type SearchMetricsArgs = {
	accountId?: string
	ids: string[]
	includeSubaccounts?: boolean
}

export function searchMetrics(args: SearchMetricsArgs) {
	return new ApiRequest({
		url: `metrics/registeredMetrics/search`,
		accountBased: true,
		accountId: args.accountId,
		subaccountsFilter: true,
		includeSubaccounts: args.includeSubaccounts,
		payload: {
			skip: 0,
			take: 1000,
			filter: {
				"logic": "and",
				"filters": [{
					"logic": "or",
					"filters": args.ids.map((id) => ({
						"operator": "eq",
						"value": id,
						"field": "metricId"
					}))
				}]
			}
		}
	})

	// if(items.length === 0)
	// 	return [];
	//
	// const url = `${Settings.serverPath}accounts/${accountId}/metrics/registeredMetrics/search`;

	// const options = {
	// 	method: 'POST',
	// 	body: JSON.stringify(),
	// 	headers: {
	// 		"Content-Type": "application/json; charset=utf-8",
	// 		"Auth-Token": Cookies.sessionId
	// 	},
	// 	throwOnError: false,
	// };
}
